import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";


 const   enhancePortfolios = async (firebase, portfolios) => {

  const balances = await Promise.all(portfolios.map(portfolio => firebase.getBalanceForPortfolio(portfolio)));


  portfolios.forEach((portfolio, index) => portfolio.balance = balances[index]);
  //

}

class BuySellBlock extends Component {

    state = {
        shares: '',
        amount: '',
        portfolio: { name: '' },
        validation: {},
        result: {},
        tmp : [],
        disabled: false
    };

    async componentDidMount() {
        this.onChange = this.onChange.bind(this);
        this.onChangePortfolio = this.onChangePortfolio.bind(this);
        this.onChangeShares = this.onChangeShares.bind(this);
        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.setFinalAmount = this.setFinalAmount.bind(this);

        if (this.props.portfolios && this.props.portfolios.length > 0) {
            const portfolio = this.props.portfolios[0];
            await enhancePortfolios(this.props.firebase, this.props.portfolios);
            this.setState({...this.state, portfolio: portfolio, tmp : this.props.portfolios});
            return;
        }

        if (this.props.portfolio) {
            this.setState({ portfolio: this.props.portfolio });
            return;
        }


        
        this.setState({ disabled: true });

    };

    validateShares(shares) {
        if (isNaN(shares)) {
            this.setState({ validation: { shares: this.props.t('buy_sell.validation.not_number') } });
            return false;
        }
        if (shares === 0) {
            this.setState({ validation: { shares: this.props.t('buy_sell.validation.more_than_0') } });
            return false;
        }
        if (shares % 1 !== 0) {
            this.setState({ validation: { shares: this.props.t('buy_sell.validation.not_decimal') } });
            return false;
        }
        return true;
    }

    validateAmount(amount) {
        if (isNaN(amount)) {
            this.setState({ validation: { amount: this.props.t('buy_sell.validation.not_number') } });
            return false;
        }
        return true;
    }

    calculateAmount(shares, price, fee) {
        return shares * price + fee
    }

    calculateShares(amount, price, fee) {
        return Math.floor((amount - fee) / price);
    }

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            validation: {},
            result: {}
        });
    };

    async onChangePortfolio(event) {
        const portfolio = this.props.portfolios[event.target.value];
        this.setState({
            portfolio: portfolio
        });
    };

    onChangeShares(event) {

        if (event.target.value) {
            const shares = +event.target.value;
            const valid = this.validateShares(shares);

            if (!valid) return;
            event.target.value = shares;

            const amount = this.calculateAmount(shares, this.getPrice(), this.props.configuration.fee);

            this.setState({ amount });
        }
        this.onChange(event);
    };

    onChangeAmount(event) {      
        this.onChange(event);
        if (event.target.value) {
            const amount = +event.target.value;
            const valid = this.validateAmount(amount);
            if (!valid) return;
            event.target.value = amount;
            const shares = this.calculateShares(amount, this.getPrice(), this.props.configuration.fee);
            this.setState({ shares });
        }
    };

    setFinalAmount() {
        if (!this.state.shares) {
          return;
        }
        const shares = this.state.shares;
        const amount = this.calculateAmount(shares, this.getPrice(), this.props.configuration.fee);
        this.setState({ amount })
    }

    submit() {
        if (!this.state.shares && !this.state.amount) {
            const sharesValidationMessage = !this.state.shares ? this.props.t('buy_sell.validation.not_defined') : '';
            const amountValidationMessage = !this.state.amount ? this.props.t('buy_sell.validation.not_defined') : '';
            this.setState({
                validation: {
                    shares: sharesValidationMessage,
                    amount: amountValidationMessage
                }
            });
            return false;
        }

        if (!this.validateShares(this.state.shares)) return false;
        if (!this.validateAmount(this.state.amount)) return false;

        return true;
    }

    render() {
        return (
            <div>
        <div className="stocks-table-row-column order-nav col-sm-12 col-xl-12">
          <div className="nav">
            <div className="nav-item">
              <span>{this.props.t('buy_sell.direct_order')} {this.props.name + ' ( ' + this.props.t('buy_sell.founds') + ' ' + (Math.round(this.state.portfolio.balance * 100) / 100).toFixed(2) + ')'}</span>
              {
                this.state.result.success
                  ?
                  <div className="result-feedback result-feedback-success">
                    {this.state.result.success}
                  </div>
                  : null
              }
              {
                this.state.result.failed
                  ?
                  <div className="result-feedback result-feedback-failed">
                    {this.state.result.failed}
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
        <div className="stocks-table-row-column order col-sm-12 col-xl-12">
          <Form onSubmit={event => {event.preventDefault();}}>
            <Form.Group>
              <Form.Label htmlFor="shares">{this.props.t('buy_sell.shares_count')}</Form.Label>
              <Form.Control
                id="shares"
                name="shares"
                type="text"
                className={this.state.validation.shares ? 'is-invalid' : ''}
                autoComplete="off"
                value={this.state.shares}
                onChange={this.onChangeShares}
                placeholder={this.props.t('buy_sell.shares_count_placeholder')}
                disabled={this.state.disabled}
              />
              {
                this.state.validation.shares
                  ?
                  <Form.Control.Feedback type="invalid">
                    {this.state.validation.shares}
                  </Form.Control.Feedback>
                  : null
              }
              
            </Form.Group>
            
            <Form.Group>
              <Form.Label htmlFor="amount">{this.props.t('buy_sell.amount')}</Form.Label>
              <Form.Control
                type="text"
                className={this.state.validation.amount ? 'is-invalid' : ''}
                id="amount"
                name="amount"
                autoComplete="off"
                value={this.state.amount}
                onChange={this.onChangeAmount}
                onBlur={this.setFinalAmount}
                placeholder={this.props.t('buy_sell.amount_placeholder')}
                disabled={this.state.disabled}
              />
              {
                this.state.validation.amount
                  ?
                  <Form.Control.Feedback type="invalid">
                    {this.state.validation.amount}
                  </Form.Control.Feedback>
                  : null
              }
            </Form.Group>
            {
              this.props.portfolios && this.props.portfolios.length > 0 ? (
                <Form.Group>
                  <Form.Label htmlFor="portfolio">{this.props.t('buy_sell.select_portfolio')}</Form.Label>
                  <Form.Control
                    as="select"
                    id="portfolio"
                    name="portfolio"
                    onChange={this.onChangePortfolio}
                    disabled={this.state.disabled}
                  >
                    {
                      this.state.tmp.map((p, index) => (
                        <option key={index} value={index}>{(p.name || '') + ' ( ' + this.props.t('buy_sell.available_founds') + ' ' + Math.round(p.balance * 100) / 100 + ')'}</option>
                      ))
                    }
                  </Form.Control>
                </Form.Group>
              ) : null
            }
            
            <Form.Group>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => this.submit()}
                disabled={this.state.disabled}
              >
                {this.props.t('buy_sell.submit')}
              </button>
            </Form.Group>
          
          </Form>
        </div>
      </div>
        )
    }
}

export default BuySellBlock;