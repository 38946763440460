import React  from 'react';


import './styles.scss';

const Cookies = () =>
    (<div className="container"><div class="cookies">
        <h5>Cookies</h5>
        <p>
<strong>Definition av  cookies</strong><br />
En cookie, eller kaka på svenska, är en liten textfil som webbplatsen begär att få lagra på besökarens dator och som innehåller en viss mängd information och en viss tidsstämpel.</p>
<p>
Webbläsaren sparar informationen på ett speciellt ställe i din dator och returnerar informationen i kakan till webbplatsen man besökt vid varje förfrågan av sidor/bilder från webbplatsen.
</p>
<p>
<strong>Två typer av cookies</strong><br />
Cookies på den här webbplatsen används för att förbättra användarupplevelsen och för optimering av webbplatsen. Det finns två typer av cookies:
<ul className="circled">

   <li> Den ena typen, som kallas för permanent cookie, sparar en fil som ligger kvar på besökarens dator. Den används till exempel för att kunna anpassa en webbplats efter besökarens önskemål, val och intressen samt för statistikuppföljning.</li>
    <li>Den andra typen kallas sessioncookie. Under tiden en besökare är inne på en webbsida, lagras den temporärt i besökarens dators minne. Sessioncookies försvinner när du stänger din webbläsare.</li>
</ul>
På vår webbplats används båda sessioncookies och permanenta cookies. Oberoende av vilken typ av cookie som används på den här webbplatsen sparas ingen personlig information om besökaren (som till exempel e-postadress eller namn).</p>
<p>
<strong>Kort om lagen om elektronisk kommunikation</strong><br />

Enligt lagen om elektronisk kommunikation SFS 2003:389, som trädde i kraft den 25 juli 2003 och ändrades 1 juli 2011, ska alla som besöker en webbplats med cookies få information om webbplatsen innehåller cookies eller inte (nästan alla webbplatser använder någon form av cookies och/eller tjänster som webbstatistiksystem som i sin tur använder cookies) och i så fall ändamålet med användningen av cookies.</p>
<p>
Man måste även informera om hur cookies kan undvikas och besökaren ska även ges möjlighet att samtycka till att till att kakor används, det vill säga sätts på besökarens dator.
</p>
<p>
På den här webbplatsen informerar vi om alla cookies som används (se nedan). Vi redovisar vad de olika kakorna heter, om det är sessioncookies eller permanenta cookies, till vilket domännamn de hör (här ser man vila cookies som är förstapartscookies (de har då samma domännamn som webbplatsen) och vilka cookies som är tredjepartscookies (de har ett annat domännamn än webbplatsen), vilken data som lagras i kakorna, vad informationen används till och hur länge cookien sparas i besökarens webbläsare.</p>
<p>
I sin webbläsare kan man ställa in om man vill undvika kakor (se mer nedan). Hur samtycke ska hanteras finns det inga fastställda regler kring i den nya lagen. På den här webbplatsen tolkar vi lagen så att man ger sitt samtycke till cookies genom att använda webbläsarens inställningar och/eller andra verktyg för hantering av cookies.
</p>
<p>
<strong>Hantering av cookies</strong><br />

Om du inte accepterar användandet av cookies kan du stänga av cookies via din webbläsares säkerhetsinställningar. Du kan även ställa in webbläsaren så att du får en varning varje gång webbplatsen försöker sätta en cookie på din dator. Genom webbläsaren kan också tidigare lagrade cookies raderas.
</p>
<p>
Se webbläsarens hjälpsidor för mer information om hur du kan se vilka cookie som finns lagrade i din webbläsare, hur man tar bort dem samt gör inställningar för om cookies ska accepteras eller inte. Nedan finns länkar om hur man haterar cookies i vanliga webbläsare.<br /><br />

    IE9<br />
    Firefox<br />
    Google Chrome<br />
<br /><br />
Några andra artiklar om hantering av cookies i olika webbläsare.<br /><br />

    Google Chrome<br />
    Var sparas cookies i  Internet Explorer?<br />
    Ta bort cookies i firefox<br />
<br />
<strong>Cookies på den här webbplatsen</strong><br />

Nedan presenteras de cookies som används på den här webbplatsen. Uppsättningen cookies som används på en webbplats varierar. Observera även att det kan finnas cookies som är knutna endast till en del av webbplatsen och/eller kopplade till någon form av tjänst på webbplatsen.</p>
<p>
Om det skulle förekomma några felaktigheter eller om det är några frågetecken kring de cookies som används på den här webbplatsen är du välkommen att höra av dig via vår kontaktsida.
</p>
<p>
Information om de cookies som används på aktiespelet.se hittar du under följande prefix:<br /><br />

    Namn – namn på cookien<br />
    Typ – sessioncookie eller permanent cookie (är kopplat till tiden som cookien ”lever”)<br />
    Domännamn – vilken domän som sätter cookien (här kan man utläsa om det är förstapartscookies eller tredjepartscookies)<br />
    Data – vilken data som lagras i kakorna<br />
    Användning – vad informationen används till<br />
    Tid – hur länge cookien sparas i besökarens webbläsare
    </p>
    <p>
<strong>Namn: __utma</strong><br />
Typ: Permanent cookie från webbstatistiksystemet Google Analytics<br />
Domännamn: aktiespelet.se<br />
Data: unikt nummer som identifierar besökaren, antal besök samt tider för första besök, föregående besök och när nuvarande besök påbörjades samt tidpunkten för sista interaktionen med webbplatsen. Delar av informationen uppdateras vid varje sidvisning.<br />
Användning: Webbstatistik, används för att beräkna antalet unika besökare till webbplatsen.<br />
Tid: Cookien förfaller två år från det att den sattes eller senast uppdaterades.
</p>
<p>
<strong>Namn: __utmb</strong><br />
Typ: Sessionscookie från webbstatistiksystemet Google Analytics<br />
Domännamn: aktiespelet.se<br />
Data: unikt nummer, antal sidor som visats under nuvarande besök, tid när nuvarande besök påbörjades. Delar av informationen uppdateras vid varje sidvisning.<br />
Användning: Webbstatistik, används för att beräkna antalet besök på webbplatsen.<br />
Tid: Cookien förfaller 30 minuter från det att den sattes eller senast uppdaterades. Observera att 30-minutersgränsen kan ändras genom att använda en specifik parameter, så det är inte helt säkert att det är exakt 30 minuter på din webbplats, men standard är 30 minuter.
</p>
<p>
<strong>Namn: __utmc från webbstatistiksystemet Google Analytics</strong><br />
Typ: Sessionscookie<br />
Domännamn: aktiespelet.se<br />
Data: unikt nummer<br />
Användning: Webbstatistik, används ihop med cookien __utmb för att räkna ut om det är ett nytt besök enligt definitionen av besök i webbstatistiksystemet (30 minuters inaktivitet innebär ett nytt besök i Google Analytics). Om besökaren är inne på din webbplats och sedan stänger webbläsaren och därefter öppnar en ny webbläsare (av samma typ) och kommer tillbaka inom 30 minuter så kan Google Analytics avgöra om besöker ska tolkas som ett nytt besök eller inte (__utmb cookien lever fortfarande eftersom 30 minutersgränsen inte passerats).<br />
Tid: Cookien förfaller när webbläsaren stängs.</p>
<p>

<strong>Namn: __utmz från webbstatistiksystemet Google Analytics</strong><br />
Typ: Permanent cookie<br />
Domännamn: aktiespelet.se<br />
Data: unikt nummer, tid för klick från trafikkälla, räknare för trafikkälla samt namn på/typ av trafikkälla, sökord från externa sökmotorer och Google AdWords information via parametrarna utmcsr, utmcmd, utmctr, utmcct, utmccn och utmglcid.<br />
Användning: Webbstatistik, används för att mäta trafikkällor och navigering på webbplatsen. Informationen i denna cookie är speciellt intressant om man vill koppla konverteringar till olika trafikkällor och kampanjer. Delar av cookien uppdateras varje gång besökaren kommer in från en ny trafikkälla och andra delar (den för navigering) uppdateras vid varje sidvisning.<br />
Tid: Cookien förfaller sex månader efter att den sattes eller senast uppdaterades.<br />

Mer information om de cookies som Google Analytics sätter finns här: <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?csw=1" target="_blank">Cookies & Google Analytics</a>.
        </p>
       
    </div></div>
);


export default Cookies;