import React, { Component } from "react";
import { withAuthorization } from "../Session";
import Portfolio from "./Portfolio";
import InactivePortfolio from "./InactivePortfolio";


import moment from "moment-timezone";

import './style.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/fontawesome-free-regular";
import { convertCompetitionDate } from "../../utils/display";
import { ButtonToolbar , Badge} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { isCompetitionAvailable } from "../../utils/common";

import  AbstractComponent  from "../AbstractComponent";


const FILTERS = {
    ACTIVE: 'ACTIVE',
    FINISHED: 'FINISHED'
};

const groupingPortfoliosByCompetitions = (portfolios) => {
    const now = moment();
    return portfolios
        .map(item => item['competition']['id'])
        .filter((item, index, array) => array.indexOf(item) === index)
        .map(item => ({ id: item, portfolios: portfolios.filter(p => p['competition']['id'] === item) }))
        .map(item => ({ ...item, ...item.portfolios[0]['competition']}))
        .map(item => ({ ...item,  leftToStart : now.diff(item.startDate.toDate(), 'hours') }))
        .map(item => {
          return item;
        })

        ;
};

const getInProgressCompetitions = (portfolios) => {
    return groupingPortfoliosByCompetitions(portfolios.filter((item) =>
        item.competition && moment().isBefore(item.competition.endDate.toDate()) && isCompetitionAvailable(item.competition, new Date())
    ));
};

const getFinishedCompetitions = (portfolios) => {
    return groupingPortfoliosByCompetitions(portfolios.filter((item) =>
        item.competition && moment().isAfter(item.competition.endDate.toDate())
    ));
};

const getBenefitPortfolios = (portfolios) => {
    return portfolios.filter((item) => !item.competition );
};



class PortfoliosPage extends AbstractComponent {

    state = {
        competitions: [],
        isSelected: false,
        filter: FILTERS.ACTIVE
    };

    componentDidMount = async () => {
        this.scrollUp();
      
        const allPortfolios = await this.props.firebase.getPortfolios(this.props.user);

        const activeCompetitions = getInProgressCompetitions(allPortfolios);
        const finishedCompetitions = getFinishedCompetitions(allPortfolios);

        const benefitPortfolios = getBenefitPortfolios(allPortfolios)

        this.setState({ competitions: activeCompetitions, activeCompetitions, finishedCompetitions, benefitPortfolios });
    };

    onFilterClick = (filter) => {
        const competitions = filter === FILTERS.ACTIVE ? this.state.activeCompetitions : this.state.finishedCompetitions;

        this.setState({ filter, competitions });
    };


    render() {
        return (
          <div ref={this.ref}>
            <PortfoliosPageScreen 
              t={this.props.t} 
              competitions={this.state.competitions}
              benefitPortfolios={this.state.benefitPortfolios} 
              filter={this.state.filter} 
              onFilterClick={this.onFilterClick}/>
          </div>
        
        );
    }
}

const PortfoliosPageScreen = ({ t, competitions = [], benefitPortfolios = [], isSelected, filter, onFilterClick, handleSelectCompetition = () => {} }) =>
    (
        <div className="content portfolios">
            <Helmet>
                <title>{t('meta.title.profiles')}</title>
            </Helmet>     
      <div className="header">
        <div className="container">
          <h5>{t('portfolios.title')}</h5>
          <h6>{t('portfolios.your_competitions')}</h6>
        </div>
      </div>

      <div className="toolbox container">
        <div className="controls d-flex">
          <ButtonToolbar className="flex-fill justify-content-start flex-row">
            <button className={`btn btn-primary ${filter !== FILTERS.ACTIVE ? 'btn-inactive' : ''}`} onClick={() => onFilterClick(FILTERS.ACTIVE)}>{t('portfolios.active')}</button>
            <button className={`btn btn-primary ${filter !== FILTERS.FINISHED ? 'btn-inactive' : ''}`} onClick={() => onFilterClick(FILTERS.FINISHED)} style={{marginLeft: '.1em'}}>{t('portfolios.finished')}</button>
          </ButtonToolbar>
        </div>
      {
        benefitPortfolios.length > 0 && 
          <Badge variant="success">{ t('portfolios.benefit_portfolios', {portfolios : benefitPortfolios.length}) }</Badge>

      }

      

        <div>
          <small>{ t('portfolios.showing_competitions', {competitions : competitions.length}) }</small>
        </div>
      </div>
      {
        competitions.map((competition, index) => (
          <div key={index} className={"card bg-light mb-3 competition container " + (isSelected ? "active" : "")}>
            <div className="card-header " onClick={handleSelectCompetition}>
              <h6 className="competition-name">{competition.name} 

              {
                competition.leftToStart < 0 && competition.active &&
                <Badge className="competition-name-badge" variant="warning"><FontAwesomeIcon icon={faClock}/>{t('portfolios.left_to_start', {hours : Math.abs(competition.leftToStart)})}</Badge>
              }              

              {
                !competition.active &&
                <Badge className="competition-name-badge" variant="warning">{t('portfolios.disabled')}</Badge>
              }

              </h6>
              <span className="competition-dates">
                <FontAwesomeIcon icon={faCalendarAlt}/>
                    {" " + convertCompetitionDate(competition.startDate) + " - " + convertCompetitionDate(competition.endDate)}
              </span>
            </div>

            {
              filter === FILTERS.ACTIVE &&

              competition.portfolios.map((item, index) => (<Portfolio key={item.id} portfolio={item}/>))

            }            

            {
              filter === FILTERS.FINISHED &&

              competition.portfolios.map((item, index) => (<InactivePortfolio key={item.id} portfolio={item}/>))

            }
        </div>
        ))
      }
    </div>
    );


const condition = authUser => !!authUser;

export default withAuthorization(condition)(withTranslation()(PortfoliosPage));