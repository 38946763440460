import React, { Component } from 'react';


import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import FormCheck from 'react-bootstrap/FormCheck';

import DatePicker from 'react-date-picker'

import AnimatedButton from '../../AnimatedButton';

import { createCompetition } from '../../../service/http';

import moment from 'moment-timezone';



import './style.scss';

const NOW = moment().tz('Europe/Stockholm');

class CompetitionForm extends Component {


    state = {
        disabled: false,
        description: 'Awesome Description',
        enrollmentStart : NOW.toDate(),
        startDate : NOW.add(3, 'days').toDate(),
        enrollmentEnd : NOW.add(7, 'days').toDate(),
        endDate : NOW.add(4, 'days').toDate(),
        isActive : true,
        isTest : false,
        cost : 30,
        value : 150000,
        name: 'Awesome competition'
    }

    constructor(props) {
        super(props);
    }

    onChange = event => {
        this.setState({ [event.target.name] : event.target.value});
    };

    onCheckboxChange = event => {
        this.setState({ [event.target.name] : event.target.checked});
    };

    onStartDateChange = startDate => this.setState({ startDate : moment(startDate).hour(9).minute(0).second(0).millisecond(0).toDate() });
    onEndDateChange = endDate => this.setState({ endDate : moment(endDate).hour(18).minute(0).second(0).millisecond(0).toDate() });
    onEnrollmentStartDateChange = enrollmentStart => this.setState({ enrollmentStart :moment(enrollmentStart).hour(0).minute(0).second(0).millisecond(0).toDate() });
    onEnrollmentEndDateChange = enrollmentEnd => this.setState({ enrollmentEnd : moment(enrollmentEnd).hour(23).minute(59).second(59).millisecond(0).toDate() });


    onSubmit = (event) => {
        this.setState({ disabled: true });

        createCompetition(this.state).finally(() => this.setState({ disabled: false }))
    };



    render() {
        return (
            <div className="competition-form">
              <Form onSubmit={event => {event.preventDefault();}} >
                <Form.Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label className="font-weight-bold" htmlFor="name">{'Competition Name'}</Form.Label>
                    <Form.Control
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      type="text"
                      placeholder={"Name"}
                    />
                </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label className="font-weight-bold" htmlFor="email">{'Enrollment Start'}</Form.Label>
                    <DatePicker
                      onChange={this.onEnrollmentStartDateChange}
                      format="yMMdd"
                      className="competition-form-datepicker form-control"
                      value={this.state.enrollmentStart}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label className="font-weight-bold" htmlFor="email">{'Enrollment End'}</Form.Label>
                    <DatePicker
                      onChange={this.onEnrollmentEndDateChange}
                      format="yMMdd"
                      className="competition-form-datepicker form-control"
                      value={this.state.enrollmentEnd}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label className="font-weight-bold" htmlFor="email">{'Start'}</Form.Label>
                    <DatePicker
                      onChange={this.onStartDateChange}
                      format="yMMdd"
                      className="competition-form-datepicker form-control"
                      value={this.state.startDate}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label className="font-weight-bold" htmlFor="email">{'End'}</Form.Label>
                    <DatePicker
                      onChange={this.onEndDateChange}
                      format="yMMdd"
                      className="competition-form-datepicker form-control"
                      value={this.state.endDate}
                    />
                  </Form.Group>
                </Form.Row>



                <Form.Row>
                  <Form.Group as={Col} md={6}>
                  <Form.Label className="font-weight-bold" htmlFor="username">{'Cost'}</Form.Label>
                  <Form.Control
                    id="cost"
                    name="cost"
                    value={this.state.cost}
                    onChange={this.onChange}
                    type="number"
                    />

                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label className="font-weight-bold" htmlFor="email">{'Value'}</Form.Label>
                  <Form.Control
                    id="value"
                    name="value"
                    value={this.state.value}
                    onChange={this.onChange}
                    type="number"
                    />
                  </Form.Group>
                </Form.Row>



                <Form.Row>
                  <Form.Group as={Col} md={12}>
                    <Form.Label className="font-weight-bold" htmlFor="description">{'Description'}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      id="description"
                      name="description"
                      autoComplete="off"
                      value={this.state.description}
                      onChange={this.onChange}
                      type="text"
                      placeholder={'Description'}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md={2}>
                    <Form.Check
                      id="isActive"
                      onChange={this.onCheckboxChange}

                      name="isActive"
                      label="Is Active"
                      checked={this.state.isActive}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Form.Check
                      id="isTest"
                      onChange={this.onCheckboxChange}

                      name="isTest"
                      label="Is Test"
                      checked={this.state.isTest}
                    />
                  </Form.Group>
                </Form.Row>
                  <AnimatedButton
                  clazz="col-md-2"
                  disabled={this.state.disabled}
                  inProgress={this.state.disabled}
                  onClick={this.onSubmit}
                  buttonText="Create"
                  />
              </Form>
            </div>
        )
    }
}

export default CompetitionForm;
