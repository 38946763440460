import React, { Component } from "react";

class AbstractComponent extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();

    }

    scrollUp = () => {
        window.scrollTo({
            top: -100,
            left: 0,
            behavior: 'smooth'
        });
    }

}

export default AbstractComponent;