import BuySellBlock from "./BuySell";
import { buyStock } from "../../service/http";
import { useTranslation, withTranslation } from "react-i18next";

class Buy extends BuySellBlock {

  getPrice() {
    return this.props.stock.askprice;
  }
  
  validateShares(shares) {
    if (!super.validateShares(shares)) return false;
    
    return true;
  }
  
  validateAmount(amount) {
    if (!super.validateAmount(amount)) return false;


    const minCost = this.props.configuration.fee + this.props.stock.bidprice;
    if (amount < minCost) {
      this.setState({validation: {amount: this.props.t('buy.validation.amount_min_cost') + ' ' + minCost}});
      return false;
    }
    return true;
  }
  
  submit() {
    if (!super.submit()) return;
    
    if (this.state.amount > this.state.portfolio.balance) {
      this.setState({
        validation: {amount: this.props.t('buy.validation.amount_more_than_balance')}
      });
      return;
    }
  
  
    this.setState({ disabled: true });
    
    const amount = this.state.amount;
  
    buyStock(this.props.marketplace, this.state.portfolio, this.props.stock, +this.state.shares, amount)
      .then(() => {

        return this.props.paymentCompleted(this.props.stock, +this.state.shares, this.state.amount).then((portfolios) => {
          let portfolio = this.state.portfolio;
          if (portfolios) {
            portfolio = portfolios.find(item => item.id === this.state.portfolio.id);
          }
          this.setState({
            shares: '',
            amount: '',
            portfolio,
            result: { success: this.props.t('common.payment.success') },
            disabled: false
          })
        });
      })
      .catch(data => {
        const message = this.props.t(`error.${data.error.unit}.${data.error.code}`);
        this.setState({
           shares: '',
           amount: '',
           result: { failed: message },
           disabled: false
        })
      });
  }
  
}

export default withTranslation()(Buy);