import { fetchStock } from "../service/millistream";
import { TRANSACTION_TYPE } from "../constants/common";

export const calculateSpent = (transactions = {}) => {
    return transactions.reduce((acc, curr) => acc + curr.amount, 0);
};

export const calculateStocksOnBalance = (transactions = {}) => {
    return transactions.reduce((acc, curr) => acc + curr.count, 0);
};

export const enhanceStocks = (stocks = []) => {


    const promises = stocks.map(item => fetchStock(item.insref));
    return Promise.all(promises)
        .then(items => {
            // multiply stock info from portfolio and realtime

            return items.map(i => ({ ...i, ...stocks.find(s => s.insref === i.insref) }));
        })
        .then(items => {
            // calculate spent by transactions
            return items.map(s => {

                const cost = calculateSpent(s.transactions);
                const stocksOnBalance = calculateStocksOnBalance(s.transactions);
                const costOfStocksOnBalance = stocksOnBalance * s.bidprice;
                const gain = costOfStocksOnBalance + cost;
                // percents should be negative also
                const gainPercent = -gain / cost * 100;
                const upturn = gain > 0;
                return { ...s, cost, costOfStocksOnBalance, gain, gainPercent, upturn }
            })
        })

};