import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from "moment-timezone";
import qs from 'query-string';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import './style.scss';

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import AnimatedButton from "../AnimatedButton";
import { Trans, withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { connect } from 'react-redux';
import { toggleLoad } from '../../redux/Actions';

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { createUser } from "../../service/http";


const personnummer = require('personnummer');
const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#\$%\^&\*_\-])(?=.{8,})");


let validation = {};


const SignUpPage = () => (
    <div className="register">
        <div className="register-pre-title">
            <div className="container">
                <Trans i18nKey="signup.title">
                    ~
                    <span>Welcome to</span>
                    <h1>CREATE ACCOUNT</h1>
                </Trans>
            </div>
        </div>
        <div className="container">
            <SignUpForm/>
        </div>
    </div>
);

const INITIAL_STATE = {
    userName: '',
    email: '',
    passwordOne: '',
    firstName: '',
    lastName: '',
    streetAddress: '',
    zipCode: '',
    place: '',
    phone: '',
    land: 'svergie',
    personal: '',
    error: null,
    validation: {},
    passwordType: 'password',
    aggreed : false,
    disabled: false
};

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();


        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        const referrer = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).referrer;

        this.interaction = Date.now();

        this.setState({ referrer })

    }

    onSubmit = async () => {
        let {
            email,
            passwordOne,
            userName,
            firstName,
            lastName,
            streetAddress,
            zipCode,
            place,
            phone,
            land,
            personal,
            referrer,
            aggreed
        } = this.state;



        this.setState({ disabled: true });
        validation = {};

        const emailRegex = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
        if (!emailRegex.test(email)) {
            validation.email = this.props.t('signup.validation.email_wrong');
        }

        if (!strongPasswordRegex.test(passwordOne)) {
            validation.passwordOne = this.props.t('signup.validation.password_wrong');
        }

        if (!userName) {
            validation.userName = this.props.t('signup.validation.required');
        }

        const phoneRegex = new RegExp("^(\\+467|07)\\d{8}$");
        if (phone && !phoneRegex.test(phone)) {
            validation.phone = this.props.t('signup.validation.phone_wrong');
        }

        const zipRegex = new RegExp("^\\d{3} ?\\d{2}$");
        if (zipCode && !zipRegex.test(zipCode)) {
            validation.zipCode = this.props.t('signup.validation.zip_wrong');
        }

        if (!personal) {
            validation.personal = this.props.t('signup.validation.required')
        }
        
        if (personal && !personnummer.valid(personal)) {
            validation.personal = this.props.t('signup.validation.personal_wrong')
        }
        
        const personalRegex = new RegExp("^\\d{6,8}-?\\d{4}$");
        if (personal && !personalRegex.test(personal)) {
            validation.personal = this.props.t('signup.validation.personal_wrong_format');
        }


        if (!aggreed) {
            validation.aggreed = this.props.t('signup.validation.aggreed');
        }

        let age = 0;
        let birthday = null;
        if (personal && personalRegex.test(personal)) {
            personal = personal.replace('-', '');

            const endsAt = personal.length === 10 ? 6 : 8;
            const dateFormat = personal.length === 10 ? 'YYMMDD' : 'YYYYMMDD';

            birthday = personal.substr(0, endsAt);

            const birthdate = moment(birthday, dateFormat);
            age = moment().diff(birthdate, 'years');

            if (age < 18) {
                validation.personal = this.props.t('signup.validation.personal_over_18');
            }
        }

        const haveErrors = Object.keys(validation).length;
        if (haveErrors) {
            this.setState({ validation: validation, disabled: false });
            this.ref.current.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        createUser({ email, userName, firstName, lastName, streetAddress, zipCode, place, phone, land, personal, age, birthday }, email, passwordOne, referrer, (Date.now() - this.interaction) / 1000  )
            .then(() => this.props.dispatch(toggleLoad(true)))
            .then(() => this.props.firebase.doSignInWithEmailAndPassword(email, passwordOne))
            .then(async () => {
                this.setState({ ...INITIAL_STATE });


                const competitions = await this.props.firebase.findAvailableCompetitions(new Date());
                if (competitions.length) {
                    this.props.history.push(ROUTES.COMPETITIONS);

                } else {
                    this.props.history.push(ROUTES.PORTFOLIOS);
                }
            }).catch(throwable => {
                if (throwable.error && throwable.error.validation) {
                    validation[throwable.error.validation] = this.props.t(throwable.error.message);

                    this.setState({ validation: validation, disabled: false });
                    this.ref.current.scrollIntoView({ behavior: 'smooth' });
                    return;
                }
                this.setState({ error: throwable.error, disabled: false });
            }).finally(() => this.props.dispatch(toggleLoad(false)));

    };

    onChange = async event => {
        const validation = Object.assign(this.state.validation, {
            [event.target.name]: ''
        });
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            [event.target.name]: value,
            validation
        });
    };

    togglePassword = () => {
        const passwordType = this.state.passwordType === 'password' ? 'text' : 'password';
        this.setState({ passwordType });
    }

    validatePassword = event => {

        const passwordOne = event.target.value;



        if (!strongPasswordRegex.test(passwordOne)) {
            validation.passwordOne = this.props.t('signup.validation.password_wrong');
        } else {
            delete validation.passwordOne;
        }

        this.setState({ validation: validation, passwordOne });

    }

    render() {
        const {
            userName,
            email,
            passwordOne,
            firstName,
            lastName,
            streetAddress,
            zipCode,
            place,
            phone,
            land,
            personal,
            error,
            validation
        } = this.state;


        const { t } = this.props;

        return (
            <div className="register-content" ref={this.ref}>
            <Helmet>
                <title>{t('meta.title.signup')}</title>
            </Helmet>
                <Form onSubmit={event => {event.preventDefault();}} autoComplete="new-password">
                    <Form.Row>
                            <Form.Group as={Col} md={6}>
                                <Form.Label className="font-weight-bold" htmlFor="email">{t('signup.email')}</Form.Label>
                                <Form.Control
                                    id="email"
                                    className={validation.email ? 'is-invalid' : ''}
                                    name="email"
                                    value={email}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t('signup.email_placeholder')}
                                    disabled={this.state.disabled}
                                />
                                {
                                    validation.email
                                        ?
                                        <Form.Control.Feedback type="invalid">
                                            {validation.email}
                                        </Form.Control.Feedback>
                                        : null
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={6}>
                                <Form.Label className="font-weight-bold" htmlFor="personal">{t('signup.personal')}</Form.Label>
                                <Form.Control
                                    id="personal"
                                    className={validation.personal ? 'is-invalid' : ''}
                                    name="personal"
                                    value={personal}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t('signup.personal_placeholder')}
                                    disabled={this.state.disabled}
                                />
                                {
                                    validation.personal
                                        ?
                                        <Form.Control.Feedback type="invalid">
                                            {validation.personal}
                                        </Form.Control.Feedback>
                                        : null
                                }
                            </Form.Group>
                           
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md={6}>
                                <Form.Label className="font-weight-bold" htmlFor="username">{t('signup.username')}</Form.Label>
                                <Form.Control
                                    id="username"
                                    className={validation.userName ? 'is-invalid' : ''}
                                    name="userName"
                                    autoComplete="off"
                                    value={userName}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t('signup.username_placeholder')}
                                    disabled={this.state.disabled}
                                />
                                {
                                    validation.userName
                                        ?
                                        <Form.Control.Feedback type="invalid">
                                            {validation.userName}
                                        </Form.Control.Feedback>
                                        : null
                                }
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                                <Form.Label className="font-weight-bold" htmlFor="passwordOne">{t('signup.password')}</Form.Label>
                                <div className="register-content-password">
                                <Form.Control
                                    id="passwordOne"
                                    className={validation.passwordOne ? 'is-invalid' : ''}
                                    autoComplete="off"
                                    name="passwordOne"
                                    value={passwordOne}
                                    onChange={this.validatePassword}
                                    type={this.state.passwordType}
                                    placeholder={t('signup.password_placeholder')}
                                    disabled={this.state.disabled}
                                />
                                {
                                    validation.passwordOne
                                        ?
                                        <Form.Control.Feedback type="invalid">
                                            {validation.passwordOne}
                                        </Form.Control.Feedback>
                                        : null
                                }
                                <FontAwesomeIcon icon={ faEye } onClick={this.togglePassword}/>
                                </div>




                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md={6}>
                            <Form.Label className="font-weight-bold" htmlFor="firstName">{t('signup.firstname')}</Form.Label>
                            <Form.Control
                                id="firstName"
                                className={validation.firstName ? 'is-invalid' : ''}
                                name="firstName"
                                value={firstName}
                                onChange={this.onChange}
                                type="text"
                                placeholder={t('signup.firstname_placeholder')}
                                disabled={this.state.disabled}
                            />
                            {
                                validation.firstName
                                    ?
                                    <Form.Control.Feedback type="invalid">
                                        {validation.firstName}
                                    </Form.Control.Feedback>
                                    : null
                            }
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                            <Form.Label className="font-weight-bold" htmlFor="sureName">{t('signup.surname')}</Form.Label>
                            <Form.Control
                                id="sureName"
                                className={validation.lastName ? 'is-invalid' : ''}
                                name="lastName"
                                value={lastName}
                                onChange={this.onChange}
                                type="text"
                                placeholder={t('signup.surname_placeholder')}
                                disabled={this.state.disabled}
                            />
                            {
                                validation.lastName
                                    ?
                                    <Form.Control.Feedback type="invalid">
                                        {validation.lastName}
                                    </Form.Control.Feedback>
                                    : null
                            }
                        </Form.Group>
                    </Form.Row>
                    
                    <Form.Row>
                        <Form.Group as={Col} md={12}>
                            <Form.Label className="font-weight-bold" htmlFor="streetAddress">{t('signup.street')}</Form.Label>
                            <Form.Control
                                id="streetAddress"
                                className={validation.streetAddress ? 'is-invalid' : ''}
                                name="streetAddress"
                                value={streetAddress}
                                onChange={this.onChange}
                                type="text"
                                placeholder={t('signup.street_placeholder')}
                                disabled={this.state.disabled}
                            />
                            {
                                validation.streetAddress
                                    ?
                                    <Form.Control.Feedback type="invalid">
                                        {validation.streetAddress}
                                    </Form.Control.Feedback>
                                    : null
                            }
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                            <Form.Group as={Col} md={4}>
                                <Form.Label className="font-weight-bold" htmlFor="zip">{t('signup.zip')}</Form.Label>
                                <Form.Control
                                    id="zip"
                                    className={validation.zipCode ? 'is-invalid' : ''}
                                    name="zipCode"
                                    value={zipCode}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t('signup.zip_placeholder')}
                                    disabled={this.state.disabled}
                                />
                                {
                                    validation.zipCode
                                        ?
                                        <Form.Control.Feedback type="invalid">
                                            {validation.zipCode}
                                        </Form.Control.Feedback>
                                        : null
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label className="font-weight-bold" htmlFor="place">{t('signup.place')}</Form.Label>
                                <Form.Control
                                    id="place"
                                    className={validation.place ? 'is-invalid' : ''}
                                    name="place"
                                    value={place}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t('signup.place_placeholder')}
                                    disabled={this.state.disabled}
                                />
                                {
                                    validation.place
                                        ?
                                        <Form.Control.Feedback type="invalid">
                                            {validation.place}
                                        </Form.Control.Feedback>
                                        : null
                                }
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label className="font-weight-bold" htmlFor="phone">{t('signup.telephone')}</Form.Label>
                                <Form.Control
                                    id="phone"
                                    className={validation.phone ? 'is-invalid' : ''}
                                    name="phone"
                                    value={phone}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t('signup.telephone_placeholder')}
                                    disabled={this.state.disabled}
                                />
                                {
                                    validation.phone
                                        ?
                                        <Form.Control.Feedback type="invalid">
                                            {validation.phone}
                                        </Form.Control.Feedback>
                                        : null
                                }
                            </Form.Group>
                    </Form.Row>
                    <Form.Row>
                            <Form.Group as={Col} md={6}>
                                <Form.Label className="font-weight-bold" htmlFor="land">{t('signup.land')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    id="land"
                                    className={validation.land ? 'is-invalid' : ''}
                                    name="land"
                                    value={land}
                                    onChange={this.onChange}
                                    disabled
                                >
                                    <option>{t('signup.lands.sweden')}</option>
                                </Form.Control>
                                {
                                    validation.land
                                        ?
                                        <Form.Control.Feedback type="invalid">
                                            {validation.land}
                                        </Form.Control.Feedback>
                                        : null
                                }
                            </Form.Group>

                    </Form.Row>
                    
                    <Form.Row>
                        <Form.Group as={Col} md={6}>
                      <input
                       name="aggreed"
                      
                      type="checkbox"
                      checked={this.state.aggreed}
                      onChange={this.onChange}
                       />
                       <Form.Label className="aggreed aggreed-text" htmlFor="phone"><span dangerouslySetInnerHTML={{ __html: t('signup.aggreed')}} /></Form.Label>



                                {
                                    validation.aggreed  && <div className="aggreed aggreed-invalid-feedback invalid-feedback" dangerouslySetInnerHTML={{ __html: validation.aggreed }}></div>
                                  
                                }

                        </Form.Group>

                        <Form.Group as={Col} md={6}>

                        {error && <div className="error-feedback">{t(error.message)}</div>}
                        <div className="d-flex flex-fill justify-content-end ">
                            <AnimatedButton
                                disabled={this.state.disabled}
                                inProgress={this.state.disabled}
                                onClick={this.onSubmit}
                                buttonText={t('signup.register')}
                            />

                        </div>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
        );
    }
}

const SignUpLink = () => (
    <p>
        Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </p>
);

const connectedFormBase = connect()(SignUpFormBase);

const SignUpForm = withFirebase(withRouter(withTranslation()(connectedFormBase)));

export default withTranslation()(SignUpPage);

export { SignUpForm, SignUpLink };
