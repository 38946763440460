import React, { Component } from "react";
import { withAuthorization } from "../Session";
import Form from "react-bootstrap/Form";
import moment from "moment-timezone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMinusCircle,
    faPlusCircle,
    faAngleLeft
} from "@fortawesome/free-solid-svg-icons";

import './style.scss';
import { Link } from "react-router-dom";
import PurchaseSwishForm from "./PurchaseSwishForm";
import { withTranslation } from "react-i18next";
import { InlineCheckout, Event } from "@bambora/checkout-sdk-web";
import { bamboraCheckout } from "../../service/http";

import Loader from 'react-loader-spinner';
import CompetitionCode from "./CompetitionCode";
import { debounce } from "../../utils/reactive";

const handlePurchasingCompetition = (competition) => {
    const format = 'YYYY.MM.DD';
    return {
        id: competition.id,
        hasCode: competition.hasCode,
        name: competition.name,
        start: moment(competition.startDate.toDate()).format(format),
        end: moment(competition.endDate.toDate()).format(format)
    }
};

const PAYMENT_METHOD = {
    CARD: 'card',
    SWISH: 'swish'
};

const calculateTax = (value) => value * 0.2;
const calculatePriceWithoutTax = (value) => value - calculateTax(value);


class PurchasePage extends Component {

    checkout = new InlineCheckout(null);
    recreateCheckout = () => {};

    state = {
        competition: {},
        count: 1,
        cost: 0,
        price: 0,
        vat: 0,
        paymentMethod: null,
        isAgreed: false,
        isCheckoutMounted : true,
        code: null,
        error: null
    };

    constructor(props) {
        super(props);
        this.onChangeCountManually = this.onChangeCountManually.bind(this);
        this.onChangeCount = this.onChangeCount.bind(this);
        this.onSelectPaymentMethod = this.onSelectPaymentMethod.bind(this);
        this.onAgreeChange = this.onAgreeChange.bind(this);
    }


    componentDidMount = async () => {
        const id = this.props.match.params.id;
        const competition = await this.props.firebase.getCompetition(id);

        if (!competition) {
            this.setState({ notExist: true });
            return;
        }

        this.recreateCheckout = debounce(() => {
            if (this.state.paymentMethod !== PAYMENT_METHOD.CARD) return;
            this.checkout.destroy();
            this.checkoutCardPayment();
        }, 1000);

        const price = competition.price.cost;

        const cost = competition.price.cost;
        const vat = calculateTax(competition.price.cost);
        const total = competition.price.cost;
        this.setState({
            competition: handlePurchasingCompetition(competition),
            price: price,
            cost: cost,
            vat: vat,
            total: total
        });
    };

    onAgreeChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }


    componentWillUnmount() {
        this.checkout && this.checkout.off(Event.Authorize);
        this.checkout && this.checkout._container && this.checkout.destroy();
    }

    onChangeCount(plus) {
        if (this.state.competition.hasCode) return;
        const count = this.state.count + plus;
        if (count < 1) return;
        const total = this.state.price * count;
        const vat = calculateTax(total);
        const cost = total;
        this.setState({ cost, count, total, vat });
        this.recreateCheckout();
    };

    onChangeCountManually(event) {
        if (isNaN(+event.target.value)) return;
        if (+event.target.value < 1) return;
        const count = +event.target.value;
        const total = this.state.price * count;
        const vat = calculateTax(total);
        const cost = total;
        this.setState({ cost, count, total: total });
        this.recreateCheckout();
    }

    onSelectPaymentMethod(paymentMethod) {
        switch (paymentMethod) {
            case PAYMENT_METHOD.CARD:
                {
                    this.checkoutCardPayment();
                    break;
                }
            case PAYMENT_METHOD.SWISH:
                {
                    this.checkout._container && this.checkout.destroy();
                    break;
                }
            default:
                break;
        }
        this.setState({ paymentMethod, error: null })
    }

    checkoutCardPayment() {
        this.setState({isCheckoutMounted : false, error: null});

        this.checkout = new InlineCheckout(null);

        const user = this.props.user;
        const container = document.getElementById('purchase_card');
        const mountPromise = this.checkout.mount(container);

        mountPromise.then(() => this.setState({isCheckoutMounted : true}));

        const checkoutPromise = bamboraCheckout(this.state.competition.id, user, this.state.count);
        Promise.all([mountPromise, checkoutPromise])
            .then(result => result[1].json())
            .then(result => this.checkout.initialize(result.token))
            .then(() => {
              // todo some handling checkout
              this.checkout.on(
                Event.Authorize,
                function(payload) {
                  console.log('Event.Authorize', payload)
                }
              );
            })
            .catch(error => {
              console.log('Error checkout', error)
              this.setState({ error: error.message })
            });
    }


    render() {
        const { t } = this.props;
        //TODO error page?
        if (this.state.notExist) return <div>{t('common.failed')}</div>;
        return (
            <PurchasePageScreen
              isCheckoutMounted={this.state.isCheckoutMounted}
              t={t}
              firebase={this.props.firebase}
              error={this.state.error}
              user={this.props.user}
              competition={this.state.competition}
              cost={this.state.cost}
              count={this.state.count}
              total={this.state.total}
              vat={this.state.vat}
              code={this.state.code}
              isAgreed={this.state.isAgreed}
              onAgreeChange={this.onAgreeChange}
              onCodeComplete={value => this.setState({ code: value })}
              paymentMethod={this.state.paymentMethod}
              onSelectPaymentMethod={this.onSelectPaymentMethod}
              onChangeCountManually={this.onChangeCountManually}
              onChangeCount={this.onChangeCount}
            />
        );
    }
}


const PurchasePageScreen = (props) => {
    const { competition, count = 1, cost, vat, total, paymentMethod, onChangeCountManually, onChangeCount, onSelectPaymentMethod, t, isCheckoutMounted } = props;
    return (
        <div className="content purchase">
      <div className="header">
        <div className="container">
          <Link className="left" to={'/competitions'}>
            <FontAwesomeIcon icon={faAngleLeft}/>
          </Link>
          <div className="text">
            <h5>{t('purchase.title')}</h5>
          </div>
          
        </div>

      </div>
        <div className="container">
          <div className="row container-body">
            <div className="col-md-8 container-body-forms">
              <div className="card card-order-summary">
                <div className="card-header card-header__dark">
                  {t('purchase.order_summary')}
                </div>
                <div className="card-body order-summary">
                  <h6>{competition.name}</h6>
                  <div className="row table">
                    <div className="col-md-3 col-4 table-column-header">{t('purchase.start_date')}</div>
                    <div className="col-md-3 col-4 table-column-header table-column-header-enddate">{t('purchase.end_date')}</div>
                    <div className="col-md-3 col-4 table-column-header table-column-header-cost">{t('purchase.cost_to_buy')}</div>
                    <div className="col-md-3 col-12 table-column-header table-column-header-amount">&nbsp;<i className="glyphicon glyphicon-plus-sign"></i></div>
                  </div>
                  <div className="row table">
                    <div className="col-md-3 col-4 table-column-cell">{competition.start}</div>
                    <div className="col-md-3 col-4 table-column-cell table-column-cell-enddate">{competition.end}</div>
                    <div className="col-md-3 col-4 table-column-cell table-column-cell-cost">{cost} :-</div>
                    <div className="col-md-3 col-12 table-column-cell table-column-cell-amount">

                      <Form className="order-summary-amount-form" onSubmit={event => {event.preventDefault();}}>
                        <FontAwesomeIcon className="order-summary-amount-form-amount__plus" icon={faMinusCircle} onClick={() => {onChangeCount(-1)}}/>
                        <Form.Group>
                          <Form.Control
                            id="count"
                            name="count"
                            className="order-summary-amount-form-amount"
                            type="text"
                            value={count}
                            onChange={onChangeCountManually}
                            autoComplete="off"
                            size="sm"
                            placeholder={t('purchase.amount_placeholder')}
                            disabled={competition.hasCode}
                            />
                        </Form.Group>
                        <FontAwesomeIcon className="order-summary-amount-form-amount__minus" icon={faPlusCircle } onClick={() => {onChangeCount(1)}}/>

                      </Form>
                    </div>
                  </div>
                  { competition.hasCode &&
                    <CompetitionCode
                      user={props.user}
                      competitionId={competition.id}
                      onComplete={value => props.onCodeComplete(value)}
                      disabled={ !props.isAgreed }
                    />
                  }
                </div>
              </div>

              <div className="card card-payment-method">
                <div className="card-header card-header__dark">
                  {t('purchase.payment_method')}
                </div>
                <div className="card-body payment-method">
                 <h6>{t('purchase.select_payment')}</h6>
                  {
                   props.isAgreed && !competition.hasCode &&
                     <React.Fragment>
                        <div className="payment-method-nav">
                         <div className="payment-method-nav-item-cards">
                            <div className={'payment-method-nav-item ' + (paymentMethod === 'card' ? 'payment-method-nav-item-active' : '')} onClick={() => {onSelectPaymentMethod('card')}}>
                              <img className="cards" src={require('../../assets/imgs/cards.png')} alt={''}/>
                            </div>
                            <div className={'payment-method-nav-item ' + (paymentMethod === 'swish' ? 'payment-method-nav-item-active' : '')} onClick={() => {onSelectPaymentMethod('swish')}}>
                              <img className="swish-logo" src={require('../../assets/imgs/swish_horizontal_main.svg')} alt={''}/>
                            </div>
                         </div>
                        </div>
                        <h6 className="payment-method-title">{t('purchase.payment_option')}
                        {
                          !isCheckoutMounted &&
                              <Loader
                                 className="spinner"
                                 type="TailSpin"
                                 color="#ea373a"
                                 height={20}
                                 width={20}
                               />
                        }
      
                        </h6>
      
                        <div style={{display: paymentMethod === 'card' ? 'flex' : 'none', height: 660 + 'px' }} id="purchase_card">
                        
      
                        </div>
                        {
                          paymentMethod === 'swish' ? (
                            <PurchaseSwishForm {...props} />
                          ) : null
                        }
                     </React.Fragment>
                  }
                 { props.error && <div className="error-feedback">{props.error}</div> }
                 <p>
                      <input
                      name="isAgreed"
                      type="checkbox"
                      checked={props.isAgreed}
                      onChange={props.onAgreeChange}
                       />
                      
                      {t('purchase.term')}
                 </p>
                 {
                  !props.isAgreed &&
                    <p className="invalid">
                      {t('purchase.must_agree')}
                    </p>
                  }
                  <div className="terms_and_conditions">
                    <Link className="left" to={'#'}>
                      Köpvillkor
                    </Link>
                   
                  |
                    <Link className="left" to={'/cookies'}>
                      cookies
                    </Link>
                  |
                    <Link className="left" to={'#'}>
                      Integritetspolicy
                    </Link>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 container-body-receipt">
              <div className="card card-order-receipt">
                <div className="card-body order-receipt">
                  <h6>{t('purchase.portfolio_name')}</h6>
                 
                  <div className="row table">
                    <div className="col-8 table-column-cell subtitle">{t('purchase.subtotal')}</div>
                    <div className="col-4 table-column-cell table-column-cell-subtotal ">{cost}:-</div>
                  </div>
                  <h6>{t('purchase.taxes')}</h6>
                  <div className="row table ">
                    <div className="col-8 table-column-cell underlined">{t('purchase.vat')}</div>
                    <div className="col-4 table-column-cell table-column-tax underlined">{vat}:-</div>
                  </div>
                  <div className="row table">
                    <div className="col-8 table-column-cell underlined">{t('purchase.order_total')}</div>
                    <div className="col-4 table-column-cell table-column-cell-total underlined">{total}:-</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
    </div>
    )
};


const condition = authUser => !!authUser;

export default withAuthorization(condition)(withTranslation()(PurchasePage));
