import React from 'react';

import * as ROLES from '../../constants/roles';

import { withFirebase } from "../Firebase";
import { withTranslation } from "react-i18next";
import withAuthorization from "../Session/withAuthorization";
import { addPortfolio } from "../../service/http";
import { Helmet } from "react-helmet";


import { fetchStocks } from "../../service/millistream";

import AnimatedButton from "../AnimatedButton";

import StockTable from "../StockTable";
import _ from "lodash";


import './styles.scss';

class SharesFilter extends React.Component {

    state = {
      saveToFilter : {}
    };

    componentDidMount = async () => {
      const marketplaces = await this.props.firebase.getMarketplaces();

      const ids = marketplaces.map(marketplace => marketplace.insref);

      const stocks = await fetchStocks(ids);
      const filter = await this.props.firebase.findStocksFilter();
      this.setState({ stocks, filter})
    };

    onAddToFilter = async () => {
      const filtered = _.remove(this.state.stocks, stock => this.state.saveToFilter[stock.insref] );

      await this.props.firebase.addToStocksFilter(filtered);

      this.setState({stocks : this.state.stocks});
    }

    render() {
      const { t } = this.props;
        return (
          <div className="container">
            <Helmet>
              <title>{t('meta.title.StockTable')}</title>
            </Helmet>

            <StockTable stocks={this.state.filter} />

            <StockTable stocks={this.state.stocks} selections={this.state.saveToFilter} />
           <AnimatedButton
                  disabled={this.state.disabled}
                  inProgress={this.state.disabled}
                  onClick={this.onAddToFilter}
                  buttonText="Add to filter"
                  />


          </div>
        );
    }
}

const condition = authUser =>  authUser && authUser.roles && authUser.roles.includes(ROLES.ADMIN)



export default withTranslation()(withFirebase(withAuthorization(condition)(SharesFilter)));
