import React from "react";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLongArrowAltUp,
    faLongArrowAltDown
} from "@fortawesome/free-solid-svg-icons";

import "../style.scss";

import Firebase from "../../Firebase";
import EditableHeader from "../../EditableHeader";

import { enhanceStocks } from "../../../utils/calculations";
import { roundAsString } from "../../../utils/math";
import { withAuthorization } from "../../Session";
import { Trans, withTranslation } from "react-i18next";


const enhancePortfolio = async (firebase, portfolio) => {
    const balance = await firebase.getBalanceForPortfolio(portfolio);

    portfolio.balance = balance;
    const gain = portfolio.value - portfolio.originalValue;
    const gainPercent = (gain / portfolio.value) * 100;
    const upturn = +gain > 0;

    return { portfolio, gain, gainPercent, upturn };

};

class InactivePortfolio extends React.Component {

    state = {
        portfolio: {},
        portfolioName: '',
        balance: 0
    };


    constructor(props) {
        super(props);

        this.paymentCompleted = this.paymentCompleted.bind(this);
        this.headerRef = React.createRef();
    }

    componentDidMount() {
        const { portfolio } = this.props;

        enhancePortfolio(this.props.firebase, portfolio).then(state => this.setState(state)).then(() => {
          this.headerRef.current && this.headerRef.current.setValue(this.state.portfolio.name);
        });
    }

    paymentCompleted() {
        const { portfolio = {} } = this.props;
        const id = portfolio.id;
        return this.props.firebase.getPortfolio(id)
            .then(p => enhancePortfolio(this.props.firebase, p))
            .then(state => this.setState({ paymentsCount: this.state.paymentsCount++, ...state }))
            .then(() => ([this.state.portfolio]));
    }

    updatePortfolioName(portfolio, name) {
        portfolio.name = name;
        this.props.firebase.updatePortfolioName(portfolio);

    }

    render() {
        const { portfolio } = this.state;
        const age = portfolio.owner ? portfolio.owner.age : '';
        const { t } = this.props;
        return (

            <div className="card-body ">
          <div className="competition-portfolio row">
            <div className="competition-portfolio-cell competition-portfolio-participant col-md-3 col-6">
              <h6>{portfolio.name}</h6>
              <span className="subtitle">{age} {t('portfolio.portfolio_name')}</span>
            </div>
            
            <div className={"competition-portfolio-cell competition-portfolio-balance col-md-2 col-4 "}>
              <h6>{roundAsString(portfolio.balance)} SEK</h6>
              <span className="subtitle">{t('portfolio.available_funds')}</span>
            </div>
            <div className={"competition-portfolio-cell competition-portfolio-value col-md-2 col-4 "}>
              <h6>{roundAsString(portfolio.value)} SEK</h6>
              <span className="subtitle">{t('portfolio.total_value')}</span>
            </div>
            
            <div className={"competition-portfolio-cell competition-portfolio-gain money col-md-2 col-1 " + (this.state.upturn ? "up" : "down")}>
              <h6>{roundAsString(this.state.gain)}</h6>
              <span className="subtitle">+/- kr</span>
            </div>
  
            <div className={"competition-portfolio-cell competition-portfolio-gain col-md-1 col-4 " + (this.state.upturn ? "up" : "down")}>
              <h6>{roundAsString(this.state.gainPercent)}</h6>
              <span className="subtitle"> +/- % </span>
            </div>
            
            <div className="competition-portfolio-cell competition-portfolio-place col-md-2 col-6">
              <span className="label-number">{portfolio.place}</span>
              <span className="subtitle"><Trans i18nKey='portfolio.current_place'>current<br/>place</Trans></span>
            </div>
          </div>

        
        </div>
        );
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withTranslation()(InactivePortfolio));