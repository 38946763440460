import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEye
} from "@fortawesome/free-solid-svg-icons";
import '../BuySell/styles.scss';
import { withAuthorization } from "../Session";
import Form from 'react-bootstrap/Form'
import moment from "moment-timezone";
import { roundAsString } from "../../utils/math";
import { fetchStocks } from "../../service/millistream";
import AbstractComponent from "../AbstractComponent";
import { withTranslation } from "react-i18next";
import Loader from 'react-loader-spinner';
import _ from "lodash";
import Alert from 'react-bootstrap/Alert';
import { Checkbox } from '@material-ui/core';

class StockTable extends AbstractComponent {

    state = {


    };



    render() {
        return (
            <div ref={this.ref} className="container stocks">
          
            <StockTableView
              {...this.props}
              isLoading={this.state.isLoading}
              stocks={this.props.stocks}
              selections={this.props.selections}
            />
          </div>
        );
    }
}


const StockTableView = ({ isLoading, stocks, t, selections }) => (

    <div className="stocks-table ">
      
      <div className="stocks-table-header row">
        <div className="stocks-table-header-column col-3  col-xl-1 buy"></div>
        <div className="stocks-table-header-column col-4 col-sm-12 col-xl-2 share">{t('stocklist.share')}</div>
        <div className="stocks-table-header-column col-2 col-sm-12 col-xl-1 percent-change">%</div>
        <div className="stocks-table-header-column col-sm-12 col-xl-1 change">+/-</div>
        <div className="stocks-table-header-column col-3 col-sm-12 col-xl-1 last">{t('stocklist.last')}</div>
        <div className="stocks-table-header-column col-sm-12 col-xl-1 buy-price">{t('stocklist.buy')}</div>
        <div className="stocks-table-header-column col-sm-12 sell col-xl-1 sell-price">{t('stocklist.sell')}</div>
        <div className="stocks-table-header-column col-sm-12  col-xl-1 highest">{t('stocklist.highest')}</div>
        <div className="stocks-table-header-column col-sm-12 col-xl-1 lowest">{t('stocklist.lowest')}</div>
        <div className="stocks-table-header-column col-sm-12 col-xl-2 volume">{t('stocklist.volume')}</div>
        {/*<div className="stocks-table-header-column col-2 col-sm-12 col-xl-1 tool">{t('stocklist.tool')}</div>*/}
      
      </div>

      {
        isLoading &&
      <Loader
         className="spinner"
         type="TailSpin"
         color="#ea373a"
         height={30}
         width={30}
       />

      }


      {stocks && stocks.map((stock, index) => (
        <div className={"stocks-table-row row" + (stock.selected ? ' selected' : '')}
             key={index}
        >
          <div className="stocks-table-row-column col-3 col-sm-12 col-xl-1 " >
            <Checkbox value="checkedA" onChange={event => selections[stock.insref] = event.target.checked } size="small"/>
          </div>
          <div className="stocks-table-row-column col-5 col-sm-12 col-xl-2 share">{stock.name}</div>
          <div className={"stocks-table-row-column col-2 col-sm-12 col-xl-1 percent-change"}>
            <span className={"value__" + (stock.upturn ? 'up' : 'down')}>{roundAsString(stock.diff1dprc) + '%'}</span>
          </div>
          <div className="stocks-table-row-column col-sm-12 col-xl-1 change" >{roundAsString(stock.diff1d)}</div>
          <div className="stocks-table-row-column col-2 col-sm-12 col-xl-1 last">{stock.lastprice}</div>
          <div className="stocks-table-row-column col-sm-12 col-xl-1 buy-price">{stock.askprice}</div>
          <div className="stocks-table-row-column col-sm-12 sell col-xl-1 sell-price">{stock.bidprice}</div>
          <div className="stocks-table-row-column col-sm-12 col-xl-1 highest">{stock.dayhighprice}</div>
          <div className="stocks-table-row-column col-sm-12 col-xl-1 lowest">{stock.daylowprice}</div>
          <div className="stocks-table-row-column col-sm-12 col-xl-2 volume">{stock.quantity}</div>
        </div>
      ))}
    </div>
);






export default withTranslation()(StockTable);