import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import {withFirebase} from "../Firebase";
import { withTranslation } from "react-i18next";

import './styles.scss';

class QA extends React.Component {

    state = {
        qas: []
    };

    componentDidMount = async () => {
    	const qasPromise = this.props.firebase.findQAs();
    	qasPromise.then(qas => this.setState({qas : qas}));

    };



    render() {
        return (
            <QAScreen qas={this.state.qas} t={this.props.t} />
        );
    }
}

const QAScreen = ({qas = [], t}) =>
    (<div className="container"><div className="qa">
               <h5>{t('faq.title')}</h5>
		<Accordion>
  				{
  					qas.map( (qa, i) => 	(	
  						<Card key={i}>
    						
      							<Accordion.Toggle as={Card.Header} eventKey={i}>
        							{qa.question}
      							</Accordion.Toggle>
    					
    						<Accordion.Collapse eventKey={i}>
      							<Card.Body>
                      <p dangerouslySetInnerHTML={{ __html: qa.answer }} />
                    </Card.Body>
    						</Accordion.Collapse>
  						</Card>   						
  					))
  				}
 			

		</Accordion>
    </div></div>);


export default withTranslation()(withFirebase(QA));