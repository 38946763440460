import React from "react";

import {
    faMinusCircle,
    faPlusCircle
} from "@fortawesome/free-solid-svg-icons";

import Alert from 'react-bootstrap/Alert'

import Loader from 'react-loader-spinner'

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNil } from "lodash";

import CompetitionDetails from "./CompetitionDetails";

import './style.scss';


class Competition extends React.Component {

  state = {};


  onBenefitPortfolioClicked = competition => {
    const onBenefitPortfolioClicked = this.props.onBenefitPortfolioClicked;

    this.setState({inProgress : true, error : false});

    onBenefitPortfolioClicked(competition).then(() => this.setState({error : false, inProgress : false})).catch(response => {
      this.setState({error : response.error, inProgress : false});
      throw response.error;
    });
  }

  onFreePortfolioClicked = competition => {
    const onFreePortfolioClicked = this.props.onFreePortfolioClicked;

    this.setState({inProgress : true, error : false});

    onFreePortfolioClicked(competition).then(() => this.setState({error : false, inProgress : false})).catch(response => {
      this.setState({error : response.error, inProgress : false});
      throw response.error;
    });
  }


   render() {

   	const c = this.props.competition;
   	const t = this.props.t;
    
    const onOpen = this.props.onOpen;

   	const onSelect = this.props.onSelect;
    const isPurchasable = this.props.isPurchasable;
   	const isLoggedIn = this.props.isLoggedIn;

    const freePortfoliosForUser = c.freePortfoliosForUser;
   	const benefitPortfolios = this.props.benefitPortfolios;

    const isButtonDisabled =  !c.selected || !isPurchasable || this.state.inProgress;
    const buttonClass = 'btn '  + (c.selected ? 'btn-primary' : 'btn-light');

    const isFreePortfoliosExist = (!isNil(freePortfoliosForUser) && freePortfoliosForUser > 0);
    const isBenefitPortfoliosExist = (!isNil(benefitPortfolios) && benefitPortfolios > 0);

    const isToShowFreePortfolios = (isLoggedIn && ( isFreePortfoliosExist || isBenefitPortfoliosExist) );
    const isLoading = isNil(freePortfoliosForUser) && isNil(benefitPortfolios);

    return (
      <div className="competitions-competition">
        <div>
          {
            this.state.error &&
            <Alert  variant='danger'>
              {t(`error.${ this.state.error.unit}.${ this.state.error.code}`)}
            </Alert>
          }
          <div className={"row table " + (c.selected ? 'table__selected table__selected__top' : '')} onClick={() => { onSelect(c.id) }}>
            <div className="col-md-2 col-12 table-column-cell table-column-cell-name">
              <div className="table-column-cell-content">
                <span className="table-column-cell-content-label2">{t('competitions.name2')}</span>
                <span className="table-column-cell-content-value">{c.name}</span>
              </div>
    
            </div>
            <div className="col-md-2 col-4 table-column-cell table-column-cell-startdate">
              <div className="table-column-cell-content">
                <span className="table-column-cell-content-label2">{t('common.start_date')}</span>
                <span className="table-column-cell-content-value">{c.start}</span>
              </div>
            </div>
            <div className="col-md-2 col-4 table-column-cell table-column-cell-enddate">
              <div className="table-column-cell-content">
                <span className="table-column-cell-content-label2">{t('common.end_date')}</span>
                <span className="table-column-cell-content-value">{c.end}</span>
              </div>
            </div>
            <div className="col-md-2 table-column-cell table-column-cell-portoliovalue">
              <div className="table-column-cell-content">
                <span className="table-column-cell-content-label2">{t('competitions.portfolio_value2')}</span>
                <span className="table-column-cell-content-value">{c.price.value + ' SEK'}</span>
              </div>
            </div>
            <div className="col-md-2 col-4 table-column-cell table-column-cell-costtobuy">
              <div className="table-column-cell-content">
                <span className="table-column-cell-content-label2">{t('competitions.cost_to_buy')}</span>
                <span className="table-column-cell-content-value">{c.price.cost + ':-'}</span>
              </div>
            </div>
            <div className="col-md-1 col-9 table-column-cell ">
      
              {
                (isLoggedIn && isLoading) &&
                <Loader
                  className="spinner"
                  type="TailSpin"
                  color="#ea373a"
                  height={20}
                  width={20}
                />
              }
      
              {
                (isToShowFreePortfolios && isFreePortfoliosExist) &&
        
        
                <button
                  type="submit"
                  disabled={isButtonDisabled}
                  className={buttonClass}
                  onClick={() => { this.onFreePortfolioClicked(c) }}
                >
                  {t('competitions.get_free', {count : freePortfoliosForUser})}
                </button>
              }
      
              {
                (isToShowFreePortfolios && !isFreePortfoliosExist && isBenefitPortfoliosExist) &&
        
                <div>
                  <button
                    type="submit"
                    disabled={isButtonDisabled}
                    className={buttonClass}
                    onClick={() => { this.onBenefitPortfolioClicked(c) }}
                  >
                    {t('competitions.benefit', {count : benefitPortfolios})}
                  </button>
        
                </div>
              }
      
              {
                (isLoggedIn && !isToShowFreePortfolios && !isLoading)  &&
                <Link  to={ isPurchasable ? ('/purchase/' + c.id) : '#'}>
                  <button
                    type="submit"
                    disabled={isButtonDisabled}
                    className={buttonClass}
                  >
                    {t('common.buy')}
                  </button>
                </Link>
              }
      
              {
                !isLoggedIn &&
                <p
                >
                  {t('common.logg_in')}
                </p>
              }
    
            </div>
            <div className="col-md-1 col-3 table-column-cell table-column-cell-expand">
              <FontAwesomeIcon className="order-summary-amount-form-amount__minus"
                               icon={!c.opened ? faPlusCircle : faMinusCircle}
                               onClick={() => { onOpen(c.id, c.opened) }}/>
            </div>
          </div>
          {c.opened && <CompetitionDetails competition={c}  t={t}/>}
        </div>
      </div>
    )}

}

export default Competition;
