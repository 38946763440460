import axios from 'axios';
import promise from 'promise';

import { getAuthHeader } from '../utils/common';


// Add a request interceptor 
const wrapper = axios.create();

wrapper.interceptors.request.use(async (config) => {
  const { Authorization } = getAuthHeader();

  //if token is found add it to the header
  if (config.method !== 'OPTIONS') {
  	config.headers.authorization =  Authorization;


  }
  return config;
});

wrapper.interceptors.response.use((response) => response.data,  (error) =>  Promise.reject(error.response.data));

export default wrapper;
