import React  from 'react';

import { Trans, withTranslation } from "react-i18next";
import {Helmet} from "react-helmet";

import './styles.scss';

const Rules  = () =>
    (<div className="container"><div className="rules">
        <h5>Aktiespelet tävlingsregler</h5>
        <p>Aktiespelets tävlingar startar i början på varje månad och pågår i 3-4
veckor. Du kan anmäla dig till tävlingen och köpa aktieportföljer 7
dagar innan tävlingsstarten samt 5 dagar efter att tävlingen har
startat. Observera att anmälan är bindande. Anmälningsavgiften är
50 kr per aktieportfölj. Lägsta ålder för att delta i Aktiespelet Sveriges
AB:s aktietävlingar är 18 år.</p>
    

        <h5>Så här går tävlingen till</h5>
        <p>
Du köper en aktieportfölj till ett fiktivt värde av 100.000
kr. Aktieportföljen får innehålla aktier som du väljer från
OMX aktielistor. Det gäller att försöka välja de aktier
som du tror ger högsta värdeökning under tävlingsperioden. Värdet
på din portfölj räknas från den dag då tävlingen startar.
        </p>         

        <h5>Handel</h5>
        <p>
Du får under tävlingens gång köpa och sälja aktier hur
ofta du vill för att uppnå mesta möjliga värdeökning. Köp
respektive försäljning av aktier sker den tid du lägger en
köp/säljorder till aktuell köp respektive säljkurs. All
handel av aktier sker till realtidskurser. Aktiekurserna
som visas är med 15 min fördröjning.
        </p>        

        <div className="example">
        <h5>Exempel</h5>
        <p>
Har portföljvärdet ökat till 110.000 kr kan du köpa och
sälja aktier för det värdet. Har däremot din aktieportfölj
minskat i värde till 90.000 kr kan du bara köpa och
sälja för max det beloppet.
        </p>
        </div>        
        <p>
Aktier köps i kronor och endast i hela aktier.
Man kan handla aktier på 2 sätt:
        </p> 

        <ol>
            <li>
Ange hur många aktier du vill ha så räknar
systemet ut totala kostnaden.
            </li>              
            <li>
Ange hur mycket du vill köpa aktier för. Systemet
räknar ut hur många aktier du får. Här avrundas
det neråt.
            </li>                        
        </ol>
        <p>
Du har också möjlighet att ha ditt kapital liggande i
kassan mot 0% ränta.
        </p> 
        
        <h5>Courtage</h5>
        <p>
Varje gång du byter köper eller säljer aktier i din portfölj
dras en courtageavgift på mellan 60 - 65 kr på beloppet.
</p> 
        
        <h5>Portföljplacering</h5>
        <p>
Uppdatering av portföljer sker under kvällen.
Aktiekurserna som visas är fördröjda med 15 minuter.
        </p>         

        <h5>Händelser</h5>
        <p>
En aktie kan påverkas av flera olika händelser på börsen.
Nedan kan du läsa vilka händelser Aktiespelet hanterar och
hur dessa påverkar dig.
        </p> 

        <ul className="circled">
            <li>
                  <span className="title">Split</span>
                  <p>Vid en split skapas fler aktier. Om splitten är 2:1
(dubblering) kommer du få dubbelt så många
aktier, medan kursen halveras. En split ger dig
alltså inte mer pengar.</p>

            </li>              <li>
                  <span className="title">Omvänd split</span>
                  <p>Vid en omvänd split minskas antalet aktier. Om
splitten är 1:2 (halveras) kommer du få hälften så
många aktier, medan kursen dubblas.</p>

            </li>              <li>
                  <span className="title">Fondemission</span>
                  <p>För dig som aktieägare påverkas du av en
fondemission på samma sätt som vid en split. Dvs
förändring av antalet aktier men samma
totalvärde.</p>

            </li>              <li>
                  <span className="title">Nyemission</span>
                  <p>Vid en nyemission ger börsbolaget ut fler aktier
som de vill sälja till dig. Detta sker med hjälp av
teckningsrätter. Har du 20 aktier får du t ex 20
teckningsrätter (kan vara mer eller mindre).
Eftersom priset för dessa nya aktier brukar vara
förmånligt har teckningsrätterna ett värde.
Aktiespelet räknar ut det teoretiska värdet på de
teckningsrätter du fått och säljer dessa
automatiskt. Efter en nyemission får du i Aktiespelet mer pengar i kassan.</p>

            </li>              <li>
                  <span className="title">Utdelning</span>
                  <p>Då ett bolag har utdelning får du detta som
pengar i kassan. Du måste äga (eller köpa)
aktierna senast samma dag som boldagsstämman
äger rum för att få en utdelning (Du får alltså inte
sälja aktierna på stämmodagen). Du kan däremot
sälja aktien dagen efter stämman och ändå få
utdelningen.</p>

            </li>               
                      
        </ul>  

        <p>
Om en händelse (ex. split, emission) inte är jämnt delbar
med ditt innehav säljs de överskjutande delarna och
läggs som pengar i kassan.        
        </p>         

        <p>
Alla ovanstående händelser sker i samband med
uppdateringen efter börsens stängning.
I sällsynta fall kan andra händelser än de som är
nämnda inträffa. Dessa påverkar sällan ditt portföljvärde
och hanteras därför ej i Aktiespelets tävlingar       
        </p> 

                     <h5>Vem vinner?</h5>
        <p>
Den spelare som har högst portföljvärde efter
uppdateringen på kvällen efter sista tävlingsdagen vinner
tävlingen.
        </p>         
        <p>
Om fler tävlande har samma värde delas vinsten mellan
dessa personer.
        </p> 

        <h5>Tävlingsjury</h5>
        <p>
Tvister och oklarheter i tävlingsfrågor avgörs av en jury
bestående av: Joakim Han, Marcus Hahn, Victor
Dahlborg.
        </p>         <p>
Juryn förbehåller sig rätten att under tävlingens gång
plocka bort aktier som ej klarar tävlingens krav. Juryn
har också rätt att i efterhand korrigera handel med aktier
som gjorts till felaktig kurs.
        </p> 
        <p>
Tävlingsdeltagare som uppenbart missbrukat tävlingens
regler kan utesluts av tävlingsjuryn.
        </p>         
        <p>
Tävlingsjuryns beslut kan ej överklagas.
        </p>         
        <p>
Aktiespelet Sverige AB tar ej ansvar för eventuella el, tele- eller
datafel eller andra tekniska fel som kan uppstå under
tävlingens gång.
        </p>  
    </div></div>
);


export default Rules;