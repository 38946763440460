import _ from 'lodash';

class User {
    constructor(userObject) {
        Object.assign(this, userObject);
    }

    isAdmin() {
        return this.roles && _.includes(this.roles, 'admin');
    }    
}

export default User;