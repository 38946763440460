import React from 'react';
import { Link } from 'react-router-dom';
import { SignInForm } from "../SignIn";
import * as ROUTES from "../../constants/routes";
import Competitions from "./Competitions";
import LeadingPortfolios from "./LeadingPortfolios";
import Duels from "./Duels";
import Winners from "./Winners";
import { withAuthorization } from "../Session";

import { withFirebase } from "../Firebase";

import withNonAuthorizationOnly from "../Session/withNonAuthoriztionOnly";
import { Trans, withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Badge} from "react-bootstrap";



class LandingPage extends React.Component {

    state = {}

    componentDidMount = async () => {
        const landingPromise = this.props.firebase.findLanding();
        landingPromise.then(content => this.setState({ content: content }));

    };

    render = () =>
        {
            const banner = this.state.content ? this.state.content.topbanner.content : '';
            const content = this.state.content ? this.state.content : {};
            return (<div>
                    <Helmet>
                        <title>{this.props.t('meta.title.landing')}</title>
                    </Helmet>        
                <header>
                    <div className="slider">
                        <ul>
                            <li className="slider-list">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="text" dangerouslySetInnerHTML={{ __html: banner }} >
                                            </div>
                                        </div>
                                        <div className="col-md-5">
        
                                        {!this.props.user && <SignInForm/>}
                                            
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </header>
               
                <div className="competition">
                
                    <div className="container">
                        <header>
                            <h2>{this.props.t('landing.ongoing.title')}</h2>
                            <p>{this.props.t('landing.ongoing.description')}</p>
                        </header>
                        <Competitions/>
                    </div>
                </div>
                {/* <Duels content={content.duels}></Duels> */}


                
                {<LeadingPortfolios user={this.props.user}></LeadingPortfolios>}
                {/* <Winners></Winners> */}
        
            </div>)};

}


const condition = authUser => true;

export default withAuthorization(condition)(withTranslation()(withFirebase(LandingPage)));