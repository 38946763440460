import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { withTranslation } from "react-i18next";

import './style.scss';
import { sendmail } from "../../service/http";
import { debounce } from "../../utils/common";

import AbstractComponent from "../AbstractComponent";



const INITIAL_STATE = {
    email: '',
    subject: '',
    message: '',
    error: null,
    validation: {},
    received: false
};

const SuccessMailSent = ({ t }) => {
    return (
        <div className="support">
      <div className="container">
        <span>{t('support.mail_received')}</span>
      </div>
    </div>
    )
};

class SupportPage extends AbstractComponent {

    sendEmail = () => {};

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = { ...INITIAL_STATE };
        this.sendEmail = debounce((email, subject, message) => {

            sendmail(email, subject, message).then(response => response.json())
                .then(response => {
                    if (response.error) {
                        this.setState({ error: { message: this.props.t('support.validation.common_error') } });
                        return;
                    }
                    this.setState({ ...INITIAL_STATE, received: true });
                })
                .catch(error => {
                    // todo ? if support sender don't work, what to do? =)
                })
        }, 1000)

    }

    componentDidMount = async () => {
        this.scrollUp();
    }

    onChange = async event => {
        const validation = Object.assign(this.state.validation, {
            [event.target.name]: '' });
        this.setState({
            [event.target.name]: event.target.value, validation });
    };

    onSubmit() {
        const {
            email,
            subject,
            message
        } = this.state;

        let validation = {};
        const emailRegex = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
        if (!emailRegex.test(email)) {
            validation.email = this.props.t('common.validation.email_wrong');
        }

        if (!subject) {
            validation.subject = this.props.t('common.validation.required');
        }

        if (!message) {
            validation.message = this.props.t('common.validation.required');
        }


        this.setState({ validation: validation });

        const haveErrors = Object.keys(validation).length;
        if (!haveErrors) {
            this.sendEmail(this.state.email, this.state.subject, this.state.message);
        }
    }

    render() {
        const { t } = this.props;

        if (this.state.received) return <SuccessMailSent t={t}/>

        return (
            <div className="support">
        <div className="container">
          <div className="support-header">
            <h1>{t('support.title')}</h1>
            <span>{t('support.description')}</span>
          </div>
          <Form onSubmit={event => {event.preventDefault();}} autoComplete="new-password">
            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label className="font-weight-bold" htmlFor="email">{t('support.email')}</Form.Label>
                <Form.Control
                  id="email"
                  className={this.state.validation.email ? 'is-invalid' : ''}
                  name="email"
                  autoComplete="off"
                  value={this.state.email}
                  onChange={this.onChange}
                  type="text"
                  placeholder={t('support.email_placeholder')}
                />
                {
                  this.state.validation.email
                    ?
                    <Form.Control.Feedback type="invalid">
                      {this.state.validation.email}
                    </Form.Control.Feedback>
                    : null
                }
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label className="font-weight-bold" htmlFor="subject">{t('support.subject')}</Form.Label>
                <Form.Control
                  id="subject"
                  className={this.state.validation.subject ? 'is-invalid' : ''}
                  name="subject"
                  autoComplete="off"
                  value={this.state.subject}
                  onChange={this.onChange}
                  type="text"
                  placeholder={t('support.subject_placeholder')}
                />
                {
                  this.state.validation.subject
                    ?
                    <Form.Control.Feedback type="invalid">
                      {this.state.validation.subject}
                    </Form.Control.Feedback>
                    : null
                }
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label className="font-weight-bold" htmlFor="message">{t('support.message')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  id="message"
                  className={this.state.validation.message ? 'is-invalid' : ''}
                  name="message"
                  autoComplete="off"
                  value={this.state.message}
                  onChange={this.onChange}
                  type="text"
                  placeholder={t('support.message_placeholder')}
                />
                {
                  this.state.validation.message
                    ?
                    <Form.Control.Feedback type="invalid">
                      {this.state.validation.message}
                    </Form.Control.Feedback>
                    : null
                }
              </Form.Group>
            </Form.Row>
            <Form.Row>
              {this.state.error && <div className="error-feedback">{this.state.error.message}</div>}
              <div className="d-flex flex-fill">
                <Button className="col-md-2 btn btn-primary" type="submit" onClick={this.onSubmit}>{t('support.send')}</Button>
              </div>
            </Form.Row>
          </Form>
        </div>
      </div>
        );
    }
}

export default withTranslation()(SupportPage);