import React from 'react';

import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';


import { withFirebase } from '../../Firebase';
import Alert from 'react-bootstrap/Alert'

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col'


import personnummer from 'personnummer';

import AnimatedButton from '../../AnimatedButton';

import { sendActivationEmail } from "../../../service/http";

import './style.scss';




class ActivationAlert extends React.Component {

    state = {
        isLoading : false
    }

    onSubmit = async () => {

      this.setState({ isLoading : true});
  
      sendActivationEmail(this.props.email)
        .then(() => this.setState({ isEmailSent : true}))
        .finally( () => this.setState({ isLoading : false}));

    }



	render() {
		return (
      	<Alert  variant='danger' className="activation-alert container">
            {
                !this.state.isEmailSent &&
                <p className="activation-alert-text">
            
                        Var god aktivera ditt konto genom att klicka länk som vi skickade till {this.props.email}.
                        Om du inte fått e-postmeddelandet, kontrollerar du skräpposten eller försöker igen.
                        Tryck på knappen för att få ny länk.

                </p>
            }
            {
                this.state.isEmailSent &&
                <p className="activation-alert-text">
            
                        Aktiveringslänk var skickad till {this.props.email}.

                </p>
            }



            <AnimatedButton
                clazz="activation-alert-send-button"
                disabled={this.state.isLoading}
                inProgress={this.state.isLoading}
                onClick={this.onSubmit}
                buttonText={this.props.t('common.send')}
            />



 
        </Alert>
        )

	}

}

export default withTranslation()(ActivationAlert);
