import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { filterAvailableCompetitions, filterVisibleCompetitions, filterAvailablePortfolios} from '../../utils/common';
import moment from 'moment-timezone';

import  User from '../../models/User';



const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const COLLECTIONS = {
    USERS: 'users',
    ASSETS: 'assets',
    CONFIGURATION: 'configuration',
    COMPETITIONS: 'competitions',
    PORTFOLIOS: 'portfolios',
    PORTFOLIOS_BALANCE: 'shards_balance',
    PORTFOLIOS_COUNT: 'shards_portfolios_count',
    TRANSACTIONS: 'transactions',
    STOCKS: 'stocks',
    DUELS: 'duels',
    MARKETPLACES: 'marketplaces'
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.firestore();
    }

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    getUser = (uid) => this.db.collection(COLLECTIONS.USERS).doc(uid).get().then(doc => new User(doc.data()));
    getConfiguration = () => this.db.collection(COLLECTIONS.CONFIGURATION).doc('core').get().then(doc => doc.data());

    getCompetitionsThatEndsInFuture = (today, isAdmin) => {
        let query = this.db.collection(COLLECTIONS.COMPETITIONS).where('active', '==', true).where('endDate', '>', today);

        if (!isAdmin) {
            query = query.where('test', '==', false);
        }

        return query.orderBy('endDate', 'desc').get().then(result => result.docs.map(doc => doc.data()));
    }
    
    getLeadindPortfolios = (limit, endDate, isAdmin) => {
        let query = this.db.collection(COLLECTIONS.PORTFOLIOS).where('competition.active', '==', true).where('competition.endDate', '>', moment(endDate).add(-1, 'days').toDate());
        
        if (!isAdmin) {
            query = query.where('competition.test', '==', false)
        }

        return query.limit(limit).orderBy('competition.endDate', 'asc').orderBy('place', 'asc').get().then(result => result.docs.map(doc => doc.data()));
    }

    getCompetition = (id) => this.db.collection(COLLECTIONS.COMPETITIONS).doc(id).get().then(doc => doc.data());
    

    getPortfolios = (user) => this.db.collection(COLLECTIONS.PORTFOLIOS).where('owner.uid', '==', user.uid).get()
        .then(result => result.docs.map(doc => doc.data()));

    findBenefitPortfolios = (user) => this.db.collection(COLLECTIONS.PORTFOLIOS)
        .where('owner.uid', '==', user.uid)
        .where('orderId', '==', 'BENEFIT')
        .where('originalValue', '==', 0).get()
        .then(result => result.docs.map(doc => doc.data()));

    getAssets = (portfolio) => this.db.collection(COLLECTIONS.ASSETS).where('portfolio.id', '==', portfolio.id).get()
        .then(result => result.docs.map(doc => doc.data()));

    getBalanceForPortfolio = (portfolio) => this.db.collection(COLLECTIONS.PORTFOLIOS).doc(portfolio.id).collection(COLLECTIONS.PORTFOLIOS_BALANCE).get()
        .then(result => result.docs.map(doc => doc.data())).then(values => {
            const ret = values.reduce((acc, curr) => acc + curr.count, 0);
            return ret;
        });

    readNumberPortfoliosForCompetition = async (competition) => {
        const shards = await this.db.collection(COLLECTIONS.COMPETITIONS).doc(competition.id).collection(COLLECTIONS.PORTFOLIOS_COUNT).get().then(result => result.docs.map(doc => doc.data()));
        return shards.reduce((acc, curr) => acc + curr.count, 0);
    }

    getPortfolio = (portfolioId) => this.db.collection(COLLECTIONS.PORTFOLIOS).doc(portfolioId).get().then(doc => doc.data());

    getTransactions = (portfolio) => this.db.collection(COLLECTIONS.TRANSACTIONS).where('portfolio.id', '==', portfolio.id).get()
        .then(result => result.docs.map(doc => doc.data()));

    getMarketplaces = () => this.db.collection(COLLECTIONS.MARKETPLACES).where('active', '==', true).get().then(result => result.docs.map(doc => doc.data()));


    updatePortfolioName = (portfolio) => this.db.collection(COLLECTIONS.PORTFOLIOS).doc(portfolio.id).set({ name: portfolio.name }, { merge: true });

    updatePersonalNumber = (user, personal, birthday) => this.db.collection(COLLECTIONS.USERS).doc(user.uid).update({ personal, birthday });

    findAvailableCompetitions = (today, isAdmin) => {
        return this.getCompetitionsThatEndsInFuture(today, isAdmin).then(competitions => filterAvailableCompetitions(today, competitions));
    };
    findVisibleOngoingCompetitions = (today, isAdmin) => {
        return this.getCompetitionsThatEndsInFuture(today, isAdmin).then(competitions => filterVisibleCompetitions(today, competitions));
    };

    findPortfoliosForCompetitionsForUser = (user, competition) => {
        return this.db.collection(COLLECTIONS.PORTFOLIOS)
        .where('owner.uid', '==', user.uid)
        .where('competition.id', '==', competition.id).get()
        .then(result => result.docs.map(doc => doc.data()));
    };

    findAvailablePortfolios = (date, user) => {
        return this.db.collection(COLLECTIONS.PORTFOLIOS)
        .where('owner.uid', '==', user.uid)
        .where('competition.endDate', '>', date).get()
        .then(result => result.docs.map(doc => doc.data()))
        .then(portfolios => filterAvailablePortfolios(date, portfolios));
    };

    findAllUsers = () => this.db.collection(COLLECTIONS.USERS).get().then(result => result.docs.map(doc => doc.data()));

    findQAs = (id) => this.db.collection(COLLECTIONS.CONFIGURATION).doc('content').get().then(doc => doc.data().qa);

    findStocksFilter = () => this.db.collection(COLLECTIONS.CONFIGURATION).doc('filters').collection('stocks').get().then(result => result.docs.map(doc => doc.data()));

    addToStocksFilter = filteredStocks => {
        const promises = [];
        filteredStocks.forEach( stock => promises.push(this.db.collection(COLLECTIONS.CONFIGURATION).doc('filters').collection('stocks').add(stock)));

        return Promise.all(promises);

    }

    findDuels = (startDate) => this.db.collection(COLLECTIONS.DUELS).where('competition.active', '==', true).where('competition.startDate', '<=', startDate)
    .get().then(result => result.docs.map(doc => doc.data()));

    findLanding = () =>  this.findPage('landing')
    findDuelsPage = () =>  this.findPage('duels')

    findPage = (page) =>  this.db.collection(COLLECTIONS.CONFIGURATION + `/content/${page}`).get().then(result => result.docs.reduce((acc, curr) => {
            acc[curr.id] = curr.data();
            return acc;
        }, {}));
    
}

export default Firebase;
