import { TRANSACTION_TYPE, API_URL, API_URL_PRIVATE, API_URL_ADMIN } from "../constants/common";
import { getAuthHeader } from "../utils/common";

import axios from './wrapper';

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

const params = {
    // mode: 'no-cors',
    method: 'get',
    headers
};

const checkStatus = async (res) => {
    if (res.ok) { // res.status >= 200 && res.status < 300
        return res;
    } else {

        console.log(res)

        const throwable = await res.json();
        throwable.error = JSON.parse(throwable.error);
        throw throwable;
    }
}

const buySellStock = (marketplace, portfolio, stock, count, amount, type) => {
    const PATH = `/buy_stock`;
    const body = {
        portfolio: {
            id: portfolio.id,
            competition: {
                id: portfolio.competition.id
            }
        },
        stock,
        count,
        amount,
        type,
        marketplace
    };

    return post(API_URL_PRIVATE + PATH, body);

};

export const buyStock = (marketplace, portfolio, stock, count, amount) => {
    return buySellStock(marketplace, portfolio, stock, count, amount, TRANSACTION_TYPE.BUY);
};


export const saleStock = (marketplace, portfolio, stock, count, amount) => {
    return buySellStock(marketplace, portfolio, stock, count, amount, TRANSACTION_TYPE.SELL);
};

export const codePay = (id, code, user) => {
    const authHeaders = { ...headers, ...getAuthHeader() };
    return fetch(API_URL_PRIVATE + '/code_pay', { method: 'POST', headers: authHeaders, body: JSON.stringify({ competition: id, owner: user, code }) })
}

export const bamboraCheckout = (id, user, count) => {
    const authHeaders = { ...headers, ...getAuthHeader() };
    return fetch(API_URL_PRIVATE + '/bambora_checkout', { method: 'POST', headers: authHeaders, body: JSON.stringify({ competition: id, owner: user, count }) })
};

export const claimFreePortfolio = (competition) => {
    return post(API_URL_PRIVATE + `/competition/${competition.id}/portfolio/free`);
};

export const useBenefitPortfolio = (competition) => {
    const authHeaders = { ...headers, ...getAuthHeader() };
    return fetch(API_URL_PRIVATE + `/competition/${competition.id}/portfolio/benefit`, { method: 'POST', headers: authHeaders }).then(res => checkStatus(res));
};

export const swishCheckout = (id, user, payer, message, count) => {
    const authHeaders = { ...headers, ...getAuthHeader() };
    return fetch(API_URL_PRIVATE + '/swish_pay', { method: 'POST', headers: authHeaders, body: JSON.stringify({ competition: id, owner: user, payer, message, count }) })
};

export const sendActivationEmail = (email) => {
    const authHeaders = { ...headers, ...getAuthHeader() };
    return fetch(API_URL_PRIVATE + '/activation/send', { method: 'POST', headers: authHeaders, body: JSON.stringify({ email }) })
};

export const sendmail = (email, subject, message) => {
    return fetch(API_URL + '/sendmail', { method: 'POST', headers, body: JSON.stringify({ from: email, subject, text: message }) })
};

export const createCompetition = (competition) => {
    const authHeaders = { ...headers, ...getAuthHeader() };
    return fetch(API_URL_ADMIN + '/competition', { method: 'POST', headers: authHeaders, body: JSON.stringify(competition) });
};

export const saveUsers = () => {
    const authHeaders = { ...headers, ...getAuthHeader() };
    return fetch(API_URL_ADMIN + '/users', { method: 'GET', headers: authHeaders });
};

export const addPortfolio = (userId, competitionId, count) => {
    const authHeaders = { ...headers, ...getAuthHeader() };
    return fetch(API_URL_ADMIN + '/portfolio', { method: 'POST', headers: authHeaders, body : JSON.stringify({userId, competitionId, count} )});
};

export const isNameUnique = (name) => {
    return fetch(API_URL + '/user/unique/' + name, { method: 'GET', headers }).then(response => response.json()).then(json => json.result);
};

export const createUser = (user, email, password, referrer, interaction) => {
    const params = { user, email, password, referrer, interaction };

    return fetch(API_URL + '/user', { method: 'POST', headers, body: JSON.stringify(params) }).then(res => checkStatus(res));
};

export const post = (url, data) => {
  return axios({ method: 'post',
    url: url,
    data : data
  }).then(response => response.data);
};
