import React, { Component } from "react";
import {withFirebase} from "../../Firebase";
import * as ROUTES from "../../../constants/routes";
import moment from "moment-timezone";
import { Link } from 'react-router-dom';
import {withTranslation} from "react-i18next";



const Competitions = ({competitions = [], t}) => (

    <div className="items">
        <ul className="row">
            {competitions.map((item, index) => (
                <li key={index} className="col-md-4">
                    <div className={item.active ? 'item active' : 'item'}>
                        <div className="date">
                            <div className="from">
                                <div className="number">{item.start.day}</div>
                                {item.start.month}
                            </div>
                            <div className="appost">to</div>
                            <div className="to">
                                <div className="number">{item.end.day}</div>
                                {item.end.month}
                            </div>
                        </div>
                        <div className="text">
                            <h4>{item.name}</h4>
                            <p>{item.description}</p>
                            <Link className="more" to={ROUTES.COMPETITIONS}>{t('common.more_info')}&nbsp;&#8594;</Link>
                            
                        </div>
                    </div>
                </li>
                
            ))}
        </ul>
    </div>
);

const handleRawCompetitions = (competitions) => {
    if (!competitions) return [];
    
    return competitions.map(item => ({
        name: item.name, description: item.description,
        start: {day: moment(item.startDate.toDate()).format('DD'), month: moment(item.startDate.toDate()).format('MMMM')},
        end: {day:  moment(item.endDate.toDate()).format('DD'), month: moment(item.endDate.toDate()).format('MMMM')},
    }))
};

class CompetitionsComponent extends Component {
    
    state = {};
    
    componentDidMount() {
        //const now = new Date('2019-01-24');
        const now = new Date();
        this.props.firebase.findVisibleOngoingCompetitions(now).then((availableCompetitions) => {
            const competitionOnLive = availableCompetitions.slice(0, 3);
            this.setState({competitions: handleRawCompetitions(competitionOnLive)});
        });
    };
    
    render() {
        const { t } = this.props;
        return <Competitions competitions={this.state.competitions} t={t}/>
    }
    
    
}

export default withTranslation()(withFirebase(CompetitionsComponent));