import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";
import { codePay } from "../../../service/http";
import AnimatedButton from "../../AnimatedButton";
import './style.scss';

function CompetitionCode(props) {
  const { t, onComplete = value => {}, user, competitionId, disabled } = props;
  const [ error, setError ] = useState('');
  const [ code, setCode ] = useState('');
  const [ inProgress, setInProgress ] = useState(false);
  const [ validationStyle, setValidationStyle ] = useState('');
  
  
  const onChange = (event) => {
    const value = event.target.value
    setCode(value)
    setValidationStyle('')
  }
  
  const onBuy = () => {
    setValidationStyle('');
    setError('');
    setInProgress(true);
    codePay(competitionId, code, user)
      .then(response => response.json())
      .then(codeCheckout => {
        setInProgress(false);
        if (codeCheckout.valid) {
          onComplete(code)
          setValidationStyle('is-valid')
          return;
        }
        setError(codeCheckout.message);
        setValidationStyle('is-invalid')
      }).catch(err => {
        //todo translation
        setError(err.message);
        setInProgress(false);
    })
  }
  
  
  return (
    <Form onSubmit={event => {event.preventDefault();}}>
        <Form.Label className="font-weight-bold" htmlFor="code">{t('purchase.code_placeholder')}</Form.Label>
        <Form.Row className={'competitions-code'}>
          <Form.Control
            id="code"
            style={{width: "300px"}}
            className={validationStyle}
            name="code"
            value={code}
            onChange={onChange}
            type="text"
            disabled={inProgress}
          />
    
          <AnimatedButton
            clazz={'competitions-code-button'}
            disabled={ inProgress || disabled || !code}
            inProgress={ inProgress }
            onClick={() => onBuy()}
            buttonText={t('common.submit')}
          />
        </Form.Row>
        {
          error
            ?
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
            : null
        }
    </Form>
  )
}

export default withTranslation()(CompetitionCode)
