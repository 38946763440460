import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEye
} from "@fortawesome/free-solid-svg-icons";
import './styles.scss';
import { withAuthorization } from "../Session";
import Form from 'react-bootstrap/Form'
import moment from "moment-timezone";
import { roundAsString } from "../../utils/math";
import { fetchStocks } from "../../service/millistream";
import Buy from "./Buy";
import { isExchangeClosed } from "../../utils/common";
import AbstractComponent from "../AbstractComponent";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Loader from 'react-loader-spinner';
import _ from "lodash";
import Alert from 'react-bootstrap/Alert'

class BuySell extends AbstractComponent {

    state = {
        marketplaces: [],
        stocks: [],
        isLoading: true,
        marketplace: { symbol: '' }
    };

    componentDidMount = async () => {
        this.scrollUp();
        const marketplaces = await this.props.firebase.getMarketplaces();

        this.setState({ marketplaces });

        this.selectMarketplace(marketplaces[0].symbol);
        await this.updatePortfolios();
    };

    getBalanceForPortfolios = (portfolios) => {

    };

    updatePortfolios = async () => {
        const allPortfolios = await this.props.firebase.getPortfolios(this.props.user);
        const portfolios = allPortfolios.filter(p => p.competition && p.competition.active && moment().isBefore(p.competition.endDate.toDate()) && moment().isAfter(p.competition.startDate.toDate()));

        const notActivePortfolios = allPortfolios.filter(p => p.competition && moment().isBefore(p.competition.endDate.toDate()) && moment().isBefore(p.competition.startDate.toDate()));
        const disabledPortfolios = allPortfolios.filter(p => p.competition && !p.competition.active);


        const isNoActiveCompetitions = Boolean(!portfolios.length && notActivePortfolios.length);


        this.setState({ portfolios, isNoActiveCompetitions, isLoading: false });

        return portfolios;
    };

    paymentCompleted = () => {
        return this.updatePortfolios();
    };

    selectMarketplaceEvent = (event) => {
        this.selectMarketplace(event.target.value);
    };

    selectMarketplace = (symbol) => {
        if (!symbol) return;
        const marketplace = this.state.marketplaces.find(m => m.symbol === symbol);
        this.setState({ marketplace });
        fetchStocks([marketplace.insref])
            .then(stocks => {
                stocks = _.sortBy(stocks, ['symbol']);
                return stocks.map(s =>
                    ({
                        ...s,
                        upturn: s.diff1d > 0,
                        marketplace: marketplace.symbol
                    })
                )
            })
            .then(stocks => {
                this.setState({ stocks, [marketplace.insref]: stocks })
            })
    };

    selectStock = (stock) => {
        if (isExchangeClosed()) return;
        const stocks = this.state.stocks.map(s => s.insref === stock.insref ? { ...s, selected: true } : { ...s, selected: false });
        this.setState({ stocks });
    };

    render() {
        const exchangeClosed = isExchangeClosed();
        return (
            <div ref={this.ref} >
            <BuySellPage
            {...this.props}
            isLoading={this.state.isLoading}
            marketplace={this.state.marketplace}
            marketplaces={this.state.marketplaces}
            stocks={this.state.stocks}
            portfolios={this.state.portfolios}
            selectMarketplace={this.selectMarketplaceEvent}
            selectStock = {this.selectStock}
            paymentCompleted = {this.paymentCompleted}
            exchangeClosed={exchangeClosed}
            isNoActiveCompetitions={this.state.isNoActiveCompetitions}
          />
          </div>
        );
    }
}


const BuySellPage = ({ isNoActiveCompetitions, exchangeClosed, isLoading, marketplace, marketplaces, selectMarketplace, portfolios, stocks, selectStock, configuration, paymentCompleted, firebase, t }) => (
    <div className="stocks container" >
              <Helmet>
                <title>{t('meta.title.buysell')}</title>
            </Helmet>

    <Form className="row title" inline>
      <Form.Group>
        <Form.Label htmlFor="marketplaces"><h6>{t('stocklist.title')}</h6></Form.Label>
        <Form.Control
          as="select"
          id="marketplaces"
          name="marketplaces"
          value={marketplace.symbol}
          onChange={selectMarketplace}
        >
          {
            marketplaces.map(m => (<option key={m.symbol} value={m.symbol}>{m.name}</option>))
          }
        </Form.Control>
      </Form.Group>
    </Form>
    {
      exchangeClosed &&

      <Alert  variant='warning'>
        {t('stocklist.market_closed')}
      </Alert> 
    }  
    {
      isNoActiveCompetitions &&
      <Alert  variant='warning'>
        {t('stocklist.no_active_competitions')}
      </Alert> 
    }    

    <div className="stocks-table ">
      
      <div className="stocks-table-header row">
        <div className="stocks-table-header-column col-3  col-xl-1 buy">{t('stocklist.buy')}</div>
        <div className="stocks-table-header-column col-4 col-sm-12 col-xl-2 share">{t('stocklist.share')}</div>
        <div className="stocks-table-header-column col-2 col-sm-12 col-xl-1 percent-change">%</div>
        <div className="stocks-table-header-column col-sm-12 col-xl-1 change">+/-</div>
        <div className="stocks-table-header-column col-3 col-sm-12 col-xl-1 last">{t('stocklist.last')}</div>
        <div className="stocks-table-header-column col-sm-12 col-xl-1 buy-price">{t('stocklist.buy')}</div>
        <div className="stocks-table-header-column col-sm-12 sell col-xl-1 sell-price">{t('stocklist.sell')}</div>
        <div className="stocks-table-header-column col-sm-12  col-xl-1 highest">{t('stocklist.highest')}</div>
        <div className="stocks-table-header-column col-sm-12 col-xl-1 lowest">{t('stocklist.lowest')}</div>
        <div className="stocks-table-header-column col-sm-12 col-xl-2 volume">{t('stocklist.volume')}</div>
        {/*<div className="stocks-table-header-column col-2 col-sm-12 col-xl-1 tool">{t('stocklist.tool')}</div>*/}
      
      </div>

      {
        isLoading &&
      <Loader
         className="spinner"
         type="TailSpin"
         color="#ea373a"
         height={30}
         width={30}
       />

      }


      {stocks.map((stock, index) => (
        <div className={"stocks-table-row row" + (stock.selected ? ' selected' : '')}
             key={index}
        >
          <div className="stocks-table-row-column col-3 col-sm-12 col-xl-1 buy" buy="true">
            <span
              className={"buy-button" + (exchangeClosed ? ' disabled': '')}
              onClick={() => selectStock(stock)}
            >{t('stocklist.buy2')}</span>
          </div>
          <div className="stocks-table-row-column col-5 col-sm-12 col-xl-2 share">{stock.name}</div>
          <div className={"stocks-table-row-column col-2 col-sm-12 col-xl-1 percent-change"}>
            <span className={"value__" + (stock.upturn ? 'up' : 'down')}>{roundAsString(stock.diff1dprc) + '%'}</span>
          </div>
          <div className="stocks-table-row-column col-sm-12 col-xl-1 change" >{roundAsString(stock.diff1d)}</div>
          <div className="stocks-table-row-column col-2 col-sm-12 col-xl-1 last">{stock.lastprice}</div>
          <div className="stocks-table-row-column col-sm-12 col-xl-1 buy-price">{stock.askprice}</div>
          <div className="stocks-table-row-column col-sm-12 sell col-xl-1 sell-price">{stock.bidprice}</div>
          <div className="stocks-table-row-column col-sm-12 col-xl-1 highest">{stock.dayhighprice}</div>
          <div className="stocks-table-row-column col-sm-12 col-xl-1 lowest">{stock.daylowprice}</div>
          <div className="stocks-table-row-column col-sm-12 col-xl-2 volume">{stock.quantity}</div>

          {
            stock.selected ? (
              <Buy
                name={t('common.buy')}
                marketplace={marketplace.symbol}
                stock={stock}
                paymentCompleted={paymentCompleted}
                configuration={configuration}
                portfolios={portfolios}
                firebase={firebase}
              />
            ) : null
          }
        
        </div>
      ))}
    </div>
  </div>
);





const condition = authUser => !!authUser;

export default withAuthorization(condition)(withTranslation()(BuySell));