import { API_URL, API_URL_PRIVATE } from "../constants/common";
import { getAuthHeader } from "../utils/common";


const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

const params = {
  // mode: 'no-cors',
  method: 'get'
};

export const fetchStock = (id) => {
  const GET_PARAMS = `/stock?id=${id}`;

  const mergerdParams = {...params, headers : getAuthHeader()};


  return fetch(API_URL_PRIVATE + GET_PARAMS, mergerdParams)
    .then(response => {
      if (!response.ok) return Promise.reject();
      return response.json();
    })
};

export const fetchStocks = (ids) => {
  const GET_PARAMS = `/stocklist?marketplace=${ids.join()}`;
  const mergerdParams = {...params, headers : getAuthHeader()};
  
  return fetch(API_URL_PRIVATE + GET_PARAMS, mergerdParams)
    .then(response => {
      if (!response.ok) return Promise.reject();
      return response.json();
    })
};