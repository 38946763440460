import React from 'react';
import './style.scss';
import { roundAsString } from "../../../../utils/math";
// import { fetchStock, fetchStocks } from "../../../../service/millistream";
// import { enhanceStocks } from "../../../../utils/calculations";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Buy from "../../../BuySell/Buy";
import Sale from "../../../BuySell/Sale";
import { isExchangeClosed } from "../../../../utils/common";
import { fetchStock } from "../../../../service/millistream";
import { withTranslation } from "react-i18next";

import Loader from 'react-loader-spinner'


const handleAssets = (assets) => {

    return assets.map((asset, index) => {
        return handleAsset(asset);
    });
};

const handleAsset = (asset) => {

    return fetchStock(asset.insref).then(data => {
        const value = (asset.count * data.bidprice);
        const gain = value + asset.cost;
        const gainPercent = -gain / asset.cost;
        const upturn = gain >= 0;

        const buyprice = Math.abs(asset.cost / asset.count);

        return { ...data,
            upturn: upturn,
            buyprice : buyprice,
            count: asset.count,
            askprice: data.askprice,
            bidprice: data.bidprice,
            lastprice: data.lastprice,
            marketplace: asset.marketplace,
            insref: asset.insref,
            symbol: asset.symbol,
            value: value,
            gain: gain,
            gainPercent: gainPercent
        };
    });
};




class SharesTable extends React.Component {

    state = {
        assets: [],
        insrefToIndex: {},
        portfolio: {},
        selectedAsset: {},
        loading : true
    };

    componentDidMount() {

        this.setState({ portfolio: this.props.portfolio, stocks: this.props.stocks });
    };

    async componentDidMount() {
        const { portfolio } = this.props;
        const assets = await this.props.firebase.getAssets(portfolio);

        const insrefToIndex = {};

        assets.forEach((asset, index) => insrefToIndex[asset.insref] = index);


        const enhancedAssets = handleAssets(assets);



        Promise.all(enhancedAssets).then((result) => {
            this.setState({ assets: result, insrefToIndex: insrefToIndex, loading : false });
        });

    }


    paymentCompleted = (asset, count, amount) => {

        const index = this.state.insrefToIndex[asset.insref];

        asset.count += count;
        asset.value += -amount;
          

        if (!asset.count) {
            this.state.assets.splice(index, 1);
            delete this.state.insrefToIndex[asset.insref];

        } 


        // TODO something on this layer
        return this.props.paymentCompleted();
    };

    selectStock = (asset, marker) => {
        //if (isExchangeClosed()) return;
        if (this.state.selectedAsset) {
            delete this.state.selectedAsset.selected;

        }
        asset.selected = marker;
        this.setState({ selectedAsset: asset });
    };


    render() {
        const exchangeClosed = isExchangeClosed();
        const { t } = this.props;
        return (
            <div className="shares container">
        <h6>{t('shares_table.title')}</h6>
        <div className="shares-table ">
    
          <div className="shares-table-header row">
            <div className="shares-table-header-column  col-1">{t('shares_table.buy')}</div>
            <div className="shares-table-header-column col-1">{t('shares_table.sell')}</div>
            <div className="shares-table-header-column col-2">{t('shares_table.name')}</div>
            <div className="shares-table-header-column col-1">{t('shares_table.quantity')}</div>
            <div className="shares-table-header-column col-1">+/-%</div>
            <div className="shares-table-header-column col-1">{t('shares_table.last')}</div>
            <div className="shares-table-header-column col-1">{t('shares_table.buy_course')}</div>
            <div className="shares-table-header-column col-2">{t('shares_table.market_value')}</div>
            <div className="shares-table-header-column col-1">+/-%</div>
            <div className="shares-table-header-column col-1">+/-kr</div>
            {/*<div className="shares-table-header-column col-1">{t('shares_table.tool')}</div>*/}
          </div>
          {
            this.state.loading &&       
            <Loader
             className="spinner"
             type="TailSpin"
             color="#ea373a"
             height={30}
             width={30}
            />
          }

          {
            this.state.assets.map((asset, index) =>
              {

                if (!asset.count) return null;
                return (
                  <div key={index} >

                    <div className="shares-table-row row">
                      <div
                        className={"shares-table-row-column shares-table-row-column-buy buy up col-6 col-md-1 " + (exchangeClosed ? "disabled" : "")}
                        onClick={() => {this.selectStock(asset, 'buy')}}
                      >
                        <span>{t('shares_table.buy')}</span>
                      </div>
                      <div
                        className={"shares-table-row-column shares-table-row-column-sell down sell col-6 col-md-1 " + (exchangeClosed ? 'disabled' : '')}
                        onClick={() => {this.selectStock(asset, 'sell')}}
                      >
                        <span>{t('shares_table.sell')}</span>
                      </div>
                      <div className="shares-table-row-column shares-table-row-column-name col-md-2 col-10">{asset.symbol}</div>
                      <div className="shares-table-row-column shares-table-row-column-count col-md-1 col-3">
                        <div className="shares-table-row-column-info">
                          <span className="shares-table-row-column-count-subtitle">{t('shares_table.quantity2')}</span>
                          <span>{asset.count}</span>
                        </div>
                      </div>
                      <div className="shares-table-row-column shares-table-row-column-diff col-md-1 col-3">
                        <div className="shares-table-row-column-info">
                          <span className="shares-table-row-column-diff-subtitle">{t('shares_table.today')} %</span>
                          <span className={"value__" + (asset.diff1dprc >= 0 ? "up" : "down")}>{roundAsString(asset.diff1dprc)}</span>
                        </div>
                      
                      </div>
                      <div className="shares-table-row-column shares-table-row-column-lastprice up col-md-1 col-3">
                        <div className="shares-table-row-column-info">
                          <span className="shares-table-row-column-diff-subtitle">{t('shares_table.last2')}</span>
                          <span>{roundAsString(asset.lastprice)}</span>
                        </div>
                      
                      </div>
                      <div className="shares-table-row-column shares-table-row-column-buyCourse up col-md-1 col-3">
                        <div className="shares-table-row-column-info">
                          <span className="shares-table-row-column-diff-subtitle">{t('shares_table.buy_course2')}</span>
                          <span>{roundAsString(asset.buyprice)}</span>
                        </div>                      

                      </div>
                      <div className="shares-table-row-column shares-table-row-column-marketValue col-md-2 col-3">
                        <div className="shares-table-row-column-info">
                          <span className="shares-table-row-column-diff-subtitle">{t('shares_table.market_value2')}</span>
                          <span>{roundAsString(asset.value)}</span>
                        </div> 
                      
                      </div>
                      <div className="shares-table-row-column  shares-table-row-column-gain__percent col-md-1 col-3">
                        <div className="shares-table-row-column-info">
                          <span className="shares-table-row-column-diff-subtitle">+/- %</span>
                          <span className={"value__" + (asset.upturn ? "up" : "down")}>
                            {roundAsString(asset.gainPercent) + '%'}
                          </span>
                        </div>                       
                      </div>
                      <div className="shares-table-row-column shares-table-row-column-gain__money col-md-1 col-3">
                        <div className="shares-table-row-column-info">
                          <span className="shares-table-row-column-diff-subtitle">+/- kr</span>
                          <span className={"value__" + (asset.upturn ? "up" : "down")}>
                            {roundAsString(asset.gain)}
                          </span>
                        </div>                        
                      </div>
                      {/*<div className="shares-table-row-column shares-table-row-column-tool col-md-1 col-2">
                                              <FontAwesomeIcon icon={ faEye } onClick={() => {}}/>
                                            </div>*/}
                    </div>
                    {
                      asset === this.state.selectedAsset && asset.selected === 'buy' ? (
                        <Buy
                          name={t('common.buy')}
                          stock={asset}
                          paymentCompleted={this.paymentCompleted}
                          configuration={this.props.configuration}
                          portfolio={this.props.portfolio}
                          marketplace={asset.marketplace}
                          firebase={this.props.firebase}
                        />
                      ) : null
                    }
                    {
                      asset === this.state.selectedAsset && asset.selected === 'sell' ? (
                        <Sale
                          name={t('common.sell')}
                          stock={asset}
                          paymentCompleted={this.paymentCompleted}
                          configuration={this.props.configuration}
                          portfolio={this.props.portfolio}
                          marketplace={asset.marketplace}
                          firebase={this.props.firebase}
                        />
                      ) : null
                    }
                  </div>
                )
              }
              
            )
          }
        </div>
      </div>

        )
    }
}
export default withTranslation()(SharesTable);