import React, { Component , useState } from "react";
import { withRouter, NavLink, Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { OverlayTrigger, Tooltip, Toast } from 'react-bootstrap';

import { SignOutItem } from '../SignOut';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import { Navbar } from "react-bootstrap";
import './style.scss';
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
    faShareAlt
} from "@fortawesome/free-solid-svg-icons";

class Navigation extends Component {
    state = {
        isLoading: true,
        navExpanded: false

    };

    constructor(props) {
        super(props);
        this.setNavExpanded = this.setNavExpanded.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }
    setNavExpanded(expanded) {
        this.setState({ navExpanded: expanded });
    }

    onLinkCopied() {
      alert('copied')

    }

    closeNav() {
        this.setState({ navExpanded: false });
    }
    render() {
        const unsubscribe = this.props.store.subscribe(() => {
            const isLoading = this.props.store.getState().isLoading;
            this.setState({ isLoading });

        });
        return (

            <div id="top" className="akt-navbar sticky-top">
            { this.state.isLoading && <LinearProgress />}
        <div className="container">
            <Navbar collapseOnSelect expand="lg" expanded={this.state.navExpanded} onToggle={this.setNavExpanded}>
                <Link className='akt-navbar-brand' to={ROUTES.LANDING}><img src={require('../../assets/imgs/aktiespelet-logo.svg')} alt='' className='img-fluid logo'/></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <AuthUserContext.Consumer>
                                          
                    {value => value.authUser ? <NavigationAuth t={this.props.t} onLinkCopied={this.onLinkCopied} location={this.props.location} user={value.authUser} closeNav={this.closeNav}/> : <NavigationNonAuth t={this.props.t} />}
                </AuthUserContext.Consumer>
            </Navbar>
        </div>
    </div>
        )
    }
}


const NavigationAuth = ({ t, location, user, closeNav, onLinkCopied }) => {
  const [copied, setCopied] = useState(false);
  return (
    <React.Fragment> 
    <Toast onClose={() => setCopied(false)} show={copied} delay={1000} autohide>
      <Toast.Body>Kopierat!</Toast.Body>
    </Toast>
    <Navbar.Collapse id="basic-navbar-nav">
            <ul className='navbar-nav mr-auto'>
              <li className={'nav-item ' + (location.pathname === ROUTES.PORTFOLIOS ? 'active' : '')}>
                <NavLink className='nav-link' to={ROUTES.PORTFOLIOS} onClick={closeNav}>
                  <div className='nav-link-container'>
                    <span>{t('navigation.my_portfolios')}</span>
                  </div>
                </NavLink>
              </li>
              <li className={'nav-item ' + (location.pathname === ROUTES.BUY_SELL ? 'active' : '')}>
                <NavLink className='nav-link' to={ROUTES.BUY_SELL} onClick={closeNav}>
                  <div className='nav-link-container'>
                    <span>{t('navigation.buy_sell')}</span>
                  </div>
                </NavLink>
              </li>
              <li className={'nav-item ' + (location.pathname === ROUTES.COMPETITIONS ? 'active' : '')}>
                <NavLink className='nav-link' to={ROUTES.COMPETITIONS} onClick={closeNav}>
                  <div className='nav-link-container'>
                    <span>{t('navigation.competitions')}</span>
                  </div>
                </NavLink>
              </li>
              <li className={'nav-item ' + (location.pathname === ROUTES.CONTACT ? 'active' : '')}>
                <NavLink className='nav-link' to={ROUTES.CONTACT} onClick={closeNav}>
                  <div className='nav-link-container'>
                    <span>{t('navigation.contact')}</span>
                  </div>
                </NavLink>
              </li>   
              {
                user.isAdmin() &&
                <React.Fragment>
                <li className={'nav-item ' + (location.pathname === ROUTES.USERS ? 'active' : '')}>
                <NavLink className='nav-link' to={ROUTES.USERS} onClick={closeNav}>
                  <div className='nav-link-container'>
                    <span>{t('navigation.users')}</span>
                  </div>
                </NavLink>
              </li>                
              <li className={'nav-item ' + (location.pathname === ROUTES.FILTER ? 'active' : '')}>
                <NavLink className='nav-link' to={ROUTES.FILTER} onClick={closeNav}>
                  <div className='nav-link-container'>
                    <span>{t('navigation.filter')}</span>
                  </div>
                </NavLink>
              </li>
              </React.Fragment>
              }           

            </ul>
            <ul className='navbar-nav'>
              <li className='nav-item' ><img src={require('../../assets/imgs/icon_mail.png')} alt={''}/></li>
              <li className='nav-item nav-item-name' onClick={closeNav}>
                <OverlayTrigger
                  key='bottom'
                  placement='bottom'
                  defaultShow={false}
                  delay={100}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      Dela länken med kopisar. Du får 1 tillgodo portfölj för varje nyregistrerad spelare.
                    </Tooltip>
                  }>
                  <CopyToClipboard text={`https://aktiespelet.se/signup?referrer=${user.uid}`} onCopy={() => setCopied(true)}>
                    <FontAwesomeIcon className="nav-item-name-icon heartbeat" icon={faShareAlt} />
                  </CopyToClipboard>

                  
                </OverlayTrigger>
                
                <Link className='nav-link' to={ROUTES.PORTFOLIOS}>{user.firstName + ' ' + user.lastName}</Link>
              </li>
              <li className='nav-item' onClick={closeNav}><SignOutItem/></li>
            </ul>
        </Navbar.Collapse>
        </React.Fragment> 
)};

const NavigationNonAuth = ({ t }) => (
    <Navbar.Collapse id="basic-navbar-nav">
        <ul className='navbar-nav mr-auto'>
            <li className='nav-item'><a className='nav-link' href='/'>{t('navigation.main')}</a></li>
            {/*{TODO translations}*/}
            <li className='nav-item'><a className='nav-link' href='/competitions'>{t('navigation.competitions')}</a></li>
            <li className='nav-item'><a className='nav-link' href='/signup'>{t('navigation.signup')}</a></li>

            {/*
            <li className='nav-item'><a className='nav-link' href='#'>Aktielistor</a></li>
            <li className='nav-item'><a className='nav-link' href='#'>Topplistor</a></li> 
            */}
            <li className='nav-item'><a className='nav-link' href='/rules'>{t('navigation.rules')}</a></li>
            <li className='nav-item'><a className='nav-link' href='/qa'>{t('navigation.help')}</a></li>
        </ul>
    </Navbar.Collapse>
);

export default withTranslation()(withRouter(props => <Navigation {...props}/>));