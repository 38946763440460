import React from 'react';

import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';


import { withFirebase } from '../../Firebase';
import Alert from 'react-bootstrap/Alert'

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col'


import personnummer from 'personnummer';

import AnimatedButton from '../../AnimatedButton';

import './style.scss';




const INITIAL_STATE = {

    personal: '',

    validation: { },

};

class PersonalNumberAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onChange = async event => {
        const validation = Object.assign(this.state.validation, {
            [event.target.name]: ''
        });
        this.setState({
            [event.target.name]: event.target.value,
            validation
        });
    };

    onSubmit = async () => {


        let { personal, validation } = this.state;
        validation = {};

        if (!personal) {
            validation.personal = this.props.t('signup.validation.required')
        }
        
        if (personal && !personnummer.valid(personal)) {
            validation.personal = this.props.t('signup.validation.personal_wrong')
        }
        const personalRegex = new RegExp("^\\d{6,8}-?\\d{4}$");
        if (personal && !personalRegex.test(personal)) {
            validation.personal = this.props.t('signup.validation.personal_wrong_format_short');
        }



        let age = 0;
        let birthday = null;

        personal = personal.replace('-', '');

        const endsAt = personal.length === 10 ? 6 : 8;
        const dateFormat = personal.length === 10 ? 'YYMMDD' : 'YYYYMMDD';
        birthday = personal.substr(0, endsAt);

        const birthdate = moment(birthday, dateFormat);

        age = moment().diff(birthdate, 'years');

        if (age < 18) {
        	validation.personal = this.props.t('signup.validation.personal_over_18');
        }

        const haveErrors = Object.keys(validation).length;
        if (haveErrors) {
            this.setState({ validation: validation, disabled: false });
            return;
        }

        //19840801-6999


        this.setState({ disabled : true});

        this.props.firebase.updatePersonalNumber(this.props.user, personal, birthday).then(() => window.location.reload()).catch(err => {
            this.setState({ validation :  { personal : 'Server Error. We are on it! Please try later.' } } ) ;

            console.error(err);
        }).finally( () => this.setState({ disabled : false}));


    }

	render() {

		return (
      	<Alert  variant='danger' className="personal-number-alert container">
      	  <p className="personal-number-alert-text">

Enligt svensk lag måste alla som tävlar på Aktiespelet Sverige AB´s aktieplattform vara 18 år fyllda. Vi ber dig därför uppdatera din profil med ditt personnummer. konton utan giltigt personnummer kommer att bli inaktiva från den 19 januari 2020. För att aktivera kontot och kunna delta i tävlingarna måste ni ange ett personnummer


      	  </p>
          <Form className="personal-number-alert-form" onSubmit={event => {event.preventDefault();}}>

          <Form.Row>
            <Form.Group as={Col} md={12}>
              <Form.Control
                id="personal"
                name="personal"
                type="text"
                className={this.state.validation.personal ? 'is-invalid' : ''}
                autoComplete="off"
                value={this.state.shares}
                onChange={this.onChange}
                placeholder={this.props.t('signup.personal_placeholder')}
                disabled={this.state.disabled}
              />
              {
                this.state.validation.personal &&
                  <Form.Control.Feedback type="invalid">
                    {this.state.validation.personal}
                  </Form.Control.Feedback>

              }
              
            </Form.Group>
            <Form.Group as={Col} md={12}>

                            <AnimatedButton
                                clazz="personal-number-alert-form-button"
                                disabled={this.state.disabled}
                                inProgress={this.state.disabled}
                                onClick={this.onSubmit}
                                buttonText={this.props.t('common.save')}
                            />
            </Form.Group>
          </Form.Row>
         </Form>
        </Alert>)

	}

}

export default withFirebase(withTranslation()(PersonalNumberAlert));
