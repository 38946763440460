import { isDev } from '../utils/common';

export const TRANSACTION_TYPE = {
    BUY: 'BUY',
    SELL: 'SELL'
};


let API_DOMAIN = 'https://europe-west2-aktiespelet-89e30.cloudfunctions.net';

let API_URL_DOMAIN = 'https://aktiespelet.se/api';


if (isDev()) {
	API_DOMAIN = 'http://localhost:5000/aktiespelet-89e30/us-central1';
	API_URL_DOMAIN = 'http://localhost:5000/aktiespelet-89e30/us-central1/main/api';

}

export const API_URL = API_URL_DOMAIN;
export const API_URL_PRIVATE = `${API_DOMAIN}/private`;
export const API_URL_ADMIN = `${API_DOMAIN}/admin`;
