import React from "react";

import SharesTable from "./SharesTable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleUp,
    faAngleDown,
    faLongArrowAltUp,
    faLongArrowAltDown
} from "@fortawesome/free-solid-svg-icons";

import "../style.scss";

import Firebase from "../../Firebase";
import EditableHeader from "../../EditableHeader";
import _ from "lodash";
import { enhanceStocks } from "../../../utils/calculations";
import { roundAsString } from "../../../utils/math";
import { withAuthorization } from "../../Session";
import { Trans, withTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Badge} from "react-bootstrap";



import Spinner from "../../Spinner";

const enhancePortfolio = async (firebase, portfolio) => {
    firebase.getBalanceForPortfolio(portfolio).then(balance => portfolio.balance = balance);

    const transactions = await firebase.getTransactions(portfolio);

    portfolio.place = portfolio.place | 0;

    const transactionsByStock = _.groupBy(transactions, 'stockInsref');

    const stocks = Object.keys(transactionsByStock).map(key => {
        return {
            insref: Number(key),
            transactions: transactionsByStock[key]
        }
    })

    if (!stocks.length) return Promise.resolve({ portfolio, stocks: [], value: portfolio.balance, gain: 0, gainPercent: 0, upturn: true });

    return enhanceStocks(stocks)
        .then(stocks => {

            const costOfStocksOnBalance = stocks.reduce((acc, curr) => {
                return acc + +curr.costOfStocksOnBalance;
            }, 0);


            const value = portfolio.balance + costOfStocksOnBalance;

            let gain = stocks.reduce((acc, curr) => {
                return acc + +curr.gain;
            }, 0);

            let gainPercent = (+gain / value) * 100;

            const upturn = +gain > 0;


            return { portfolio, stocks, value, gain, gainPercent, upturn };
        });
};

class Portfolio extends React.Component {

    state = {
        stocks: [],
        portfolio: {},
        portfolioName: '',
        balance: 0,
        paymentsCompleted: 0
    };


    constructor(props) {
        super(props);

        this.paymentCompleted = this.paymentCompleted.bind(this);
        this.headerRef = React.createRef();
    }

    componentDidMount() {
        const { portfolio } = this.props;

        enhancePortfolio(this.props.firebase, portfolio).then(state => this.setState(state)).then(() => {
          this.headerRef.current && this.headerRef.current.setValue(this.state.portfolio.name);
        });
    }

    paymentCompleted() {
        const { portfolio = {} } = this.props;
        const id = portfolio.id;
        return this.props.firebase.getPortfolio(id)
            .then(p => enhancePortfolio(this.props.firebase, p))
            .then(state => this.setState({ paymentsCount: this.state.paymentsCount++, ...state }))
            .then(() => ([this.state.portfolio]));
    }

    updatePortfolioName(portfolio, name) {
        portfolio.name = name;
        this.props.firebase.updatePortfolioName(portfolio);

    }

    render() {
        const { portfolio } = this.state;
        const age = portfolio.owner ? portfolio.owner.age : '';
        const { t } = this.props;

        return (

            <div className="card-body ">
          <div className="competition-portfolio row">
            <div className="competition-portfolio-cell competition-portfolio-participant col-md-3 col-6">
              <EditableHeader ref={this.headerRef} onSubmit={(name) => this.updatePortfolioName(this.state.portfolio, name)}/>
              <span className="subtitle">{age} {t('portfolio.portfolio_name')}</span>
            </div>
            
            <div className={"competition-portfolio-cell competition-portfolio-balance col-md-2 col-4 "}>
              <h6>{roundAsString(portfolio.balance)} SEK</h6>
              <span className="subtitle">{t('portfolio.available_funds')}</span>
            </div>
            <div className={"competition-portfolio-cell competition-portfolio-value col-md-2 col-4 "}>
              <h6>{roundAsString(this.state.value)} SEK</h6>
              <span className="subtitle">{t('portfolio.total_value')}</span>
            </div>
            
            <div className={"competition-portfolio-cell competition-portfolio-gain money col-md-2 col-1 " + (this.state.upturn ? "up" : "down")}>
              <h6>{roundAsString(this.state.gain)}</h6>
              <span className="subtitle">+/- kr</span>
            </div>
  
            <div className={"competition-portfolio-cell competition-portfolio-gain col-md-1 col-4 " + (this.state.upturn ? "up" : "down")}>
              <h6>{roundAsString(this.state.gainPercent)}</h6>
              <span className="subtitle"> +/- % </span>
            </div>
            
            <div className="competition-portfolio-cell competition-portfolio-place col-md-2 col-6">

              
            { (this.state.portfolio.competition && this.state.portfolio.competition.active && moment().isAfter(this.state.portfolio.competition.startDate.toDate())) &&   
               <React.Fragment>          
                  <span className="label-number">
                    {portfolio.place || portfolio.place === 0 ? portfolio.place : <Spinner />}</span>
                  <span className="subtitle"><Trans i18nKey='portfolio.current_place'>current<br/>place</Trans></span>
                  <FontAwesomeIcon className="competition-portfolio-place-icon" icon={this.state.visibleShares ? faAngleUp : faAngleDown } onClick={() => {this.setState({visibleShares: !this.state.visibleShares})}}/>
              </React.Fragment>
            }

            {

              (this.state.portfolio.competition && !this.state.portfolio.competition.active) && 
              <Badge className="competition-name-badge" variant="warning">{t('portfolios.disabled')}</Badge>

            }
            </div>
          </div>
          <div>
            {
              this.state.visibleShares && (
                <SharesTable
                  {...this.props}
                  portfolio={portfolio}
                  stocks={this.state.stocks}
                  paymentsCount={this.state.paymentsCount}
                  paymentCompleted={this.paymentCompleted}
                />
              )
            }
          </div>
        
        </div>
        );
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withTranslation()(Portfolio));