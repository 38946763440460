import React  from 'react';


import './styles.scss';

const PrivacyPolicy = () =>
    (<div className="container"><div class="privacypolicy">
        <h5> Integritetspolicy</h5>
        <p>
Aktiespelet Sverige AB, org. nr. 559173-5328, behandlar personuppgifter i samband med handeln på https://aktiespelet.se och är personuppgiftsansvarigt för behandlingen. Personuppgifter behandlas för att Aktiespelet Sverige AB ska kunna tillhandahålla sina tjänster, huvudsakligen för följande ändamål (”Ändamålen”): 
<ul className="circled">
<li>För att skapa och upprätthålla ditt personliga konto.</li>
<li>För att hantera eventuella vinster.</li>
<li>För att besvara dina frågor och informera dig om nya eller ändrade tjänster.</li>
<li>För att skicka marknadsföringserbjudanden som nyhetsbrev.</li>
<li>För att skicka dig enkäter som ger dig möjlighet att påverka Aktiespelet Sverige AB:s erbjudanden och tjänster.</li>
</ul>
De uppgifter som behandlas är de uppgifter som du anger, t.ex. när du lägger beställningar, kontaktar kundtjänst eller dylikt. De uppgifter du anger omfattar t.ex. kontaktinformation, adress och beställda tjänster (”Personuppgifterna”). Personuppgifterna är nödvändiga för att Aktiespelet Sverige AB ska kunna uppnå Ändamålen. Viss information kan även komma att inhämtas från offentliga register eller från andra allmänt tillgängliga källor. <br />
Den huvudsakliga rättsliga grunden för behandlingen är att behandlingen är nödvändig för att Aktiespelet Sverige AB ska kunna fullgöra sina åtaganden gentemot sina kunder. Vissa behandlingar baseras även på Aktiespelet Sverige AB:s berättigade intresse att kunna bedriva sin verksamhet på ett effektivt och säkert sätt samt på Aktiespelet Sverige AB:s skyldighet att följa rättsliga förpliktelser. Personuppgifterna kan komma att delas med myndigheter och andra parter som Aktiespelet Sverige AB anlitar för att uppnå Ändamålen.  Behandlingen av Personuppgifterna kommer pågå så länge det krävs för att uppfylla Ändamålen eller så länge som Aktiespelet Sverige AB är förpliktade att göra detta. Därefter raderas Personuppgifterna.<br />
Om du har några frågor kring behandlingen av Personuppgifterna kan du kontakta aktiespelet Sverige AB via <a href="mailto:Integritetspolicy@aktiespelet.se">Integritetspolicy@aktiespelet.se</a>. Du kan även använda denna adress om du vill utöva någon av de rättigheter du enligt GDPR har som registrerad. Vänligen notera att rättigheterna enligt GDPR inte är absoluta och att ett åberopande av en rättighet därför inte nödvändigtvis leder till en åtgärd. Dina rättigheter enligt GDPR inkluderar följande:
<ul className="circled">
<li>Rätt till tillgång – Enligt artikel 15 GDPR har du rätt att ta del av Personuppgifterna och att bli informerad om behandlingen. Den informationen finns i detta dokument.</li>
<li>Rätt till rättelse – Enligt artikel 16 GDPR har du rätt att utan onödigt dröjsmål få felaktiga personuppgifter som rör dig rättade. Med beaktande av ändamålet med behandlingen har du också rätt att komplettera ofullständiga personuppgifter.</li>
<li>Rätt till radering (”rätten att bli bortglömd”) – Under vissa omständigheter har du med stöd av artikel 17 GDPR rätt att få Personuppgifterna raderade.</li>
<li> Rätt till begränsning av användning – Under vissa omständigheter har du enligt artikel 18 GDPR rätt att begränsa behandlingen av Personuppgifterna.</li>
<li>Rätt till dataportabilitet – Enligt artikel 20 GDPR har du rätt att få ut Personuppgifterna i ett strukturerat, allmänt använt och maskinläsbart format. Du har också rätt att överföra dessa uppgifter till en annan personuppgiftsansvarig.</li>
<li> Rätt att göra invändningar – Enligt artikel 21 GDPR har du rätt att invända mot viss behandling av Personuppgifterna, exempelvis sådan behandling som grundar sig i Test AB:s berättigade intressen.</li>
<li>Slutligen har du också rätt att inge klagomål till Datainspektionen, som kan kontaktas via datainspektionen@datainspektionen.se eller 08-657 61 00.</li>
</ul>
        </p>
       
    </div></div>
);


export default PrivacyPolicy;