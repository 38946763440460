import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import {withFirebase} from "../Firebase";
import { withTranslation } from "react-i18next";

import './styles.scss';

class Duels extends React.Component {

    state = {
        content: {}
    };

    componentDidMount = async () => {
    	const duelsPromise = this.props.firebase.findDuelsPage();
    	duelsPromise.then(value => {
        this.setState({ content: value.content })});

    };



    render() {
        return (
            <DuelsScreen content={this.state.content} />
        );
    }
}

const DuelsScreen = ({content}) =>
    (<div className="container">
      <div className="duels-page">
               <h5>Dueller</h5>
               <div dangerouslySetInnerHTML={{ __html: content.text }}></div>


      </div>
    </div>);


export default withTranslation()(withFirebase(Duels));