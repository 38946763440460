import React from 'react';

import * as ROLES from '../../constants/roles';

import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import { withFirebase } from "../Firebase";
import { withTranslation } from "react-i18next";
import withAuthorization from "../Session/withAuthorization";
import AnimatedButton from "../AnimatedButton";
import { addPortfolio } from "../../service/http";
import CsvDownload from 'react-json-to-csv';

import './styles.scss';

class Users extends React.Component {

    state = {
        users: [],
        competitions: [],
        emails: [],
        competitionId: '',
        selectedUser: {}
    };

    componentDidMount = async () => {
        const usersPromise = this.props.firebase.findAllUsers();
        usersPromise.then(users => {
            const emails = users.map(user => ({ email: user.email }));
            this.setState({ users, emails });

        });
    };

    onChange = async event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onCeratePortfolios = () => {

            this.setState({ disabled : true });

      addPortfolio(this.state.selectedUser.uid, this.state.competitionId, this.state.count ).finally(() => this.setState({ disabled : false }));


    }


    render() {


        return (
            (<div className="container">
      <Navbar bg="light" expand="lg">

        <CsvDownload className="btn btn-primary" data={this.state.emails} />

      </Navbar>

      {
        this.state.selectedUser &&

        <Navbar bg="light" expand="lg">
                <Form onSubmit={event => {event.preventDefault();}} >

                    <Form.Row>
                        <Form.Group as={Col} >
                                <Form.Label className="font-weight-bold" htmlFor="username">User</Form.Label>
                                <Form.Control
                                    id="userName"
                                    name="userName"
                                    autoComplete="off"
                                    value={this.state.selectedUser.userName}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={this.state.selectedUser.userName}
                                    disabled={true}
                                />
                        </Form.Group>
                        <Form.Group as={Col} >
                                <Form.Label className="font-weight-bold" htmlFor="username">Userid</Form.Label>
                                <Form.Control
                                    id="userId"
                                    name="userId"
                                    autoComplete="off"
                                    value={this.state.selectedUser.uid}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={this.state.selectedUser.uid}
                                    disabled={true}
                                />
                        </Form.Group>
                        <Form.Group as={Col} >
                                <Form.Label className="font-weight-bold" htmlFor="username">Competition</Form.Label>
                                <Form.Control
                                    id="competitionId"
                                    name="competitionId"
                                    autoComplete="off"
                                    value={this.state.competitionId}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Competition id"
                                    disabled={this.state.disabled}
                                />
                        </Form.Group>
                        <Form.Group as={Col}>
                                <Form.Label className="font-weight-bold" htmlFor="username">Count</Form.Label>
                                <Form.Control
                                    id="count"
                                    name="count"
                                    autoComplete="off"
                                    value={this.state.count}
                                    onChange={this.onChange}
                                    type="number"
                                    placeholder="count"
                                    disabled={this.state.disabled}
                                />
                        </Form.Group>

                    </Form.Row>


                    <Form.Row>
                        <div className="d-flex flex-fill justify-content-end ">
                            <AnimatedButton
                                disabled={this.state.disabled}
                                inProgress={this.state.disabled}
                                onClick={this.onCeratePortfolios}
                                buttonText="Create"
                            />

                        </div>
                    </Form.Row>
                    </Form>

        </Navbar>
      }


      <div className="users">
         <h5>Users</h5>
         <h5>{this.state.selectedUser.email}</h5>

         <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
          {
            this.state.users.map((user, i) =>
              (
                <tr key={user.uid} onClick={() => this.setState({ selectedUser : user })}>
                  <td>{i + 1}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.userName}</td>
                  <td>{user.email}</td>
                </tr>
              ))
          }

            </tbody>
        </Table>
       

      </div>
    </div>)
        );
    }
}

const condition = authUser =>  authUser && authUser.roles && authUser.roles.includes(ROLES.ADMIN)



export default withTranslation()(withFirebase(withAuthorization(condition)(Users)));
