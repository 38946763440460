import React from 'react';
import Alert from 'react-bootstrap/Alert'
import { withTranslation } from "react-i18next";



import './style.scss';

class MessagePage extends React.Component {

	state = {}

    componentDidMount() {
        const { type, code } = this.props.match.params;

        this.setState({ type, code });


    }

    render() {
        const { t } = this.props;
        return (
            <div className="container message">
            <Alert  variant={this.state.type} className="activation-alert container">
            	{t('message.' + this.state.code)}
            </Alert>
            	
			</div>
        );

    }
}

export default withTranslation()(MessagePage);