import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { connect } from 'react-redux';

import { toggleLoad } from '../../redux/Actions';

import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Trans, withTranslation } from "react-i18next";

import './style.scss';


const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
    disabled: false
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        
        this.state = { ...INITIAL_STATE };
    }
    
    onSubmit = event => {

        this.props.dispatch(toggleLoad(true));

        const { email, password } = this.state;
        this.setState({ disabled: true });
        
        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.PORTFOLIOS);
            })
            .catch(error => {
                this.setState({ error : 'signin.auth_error', disabled: false  });
            }).finally(() => this.props.dispatch(toggleLoad(false)) );
        
        event.preventDefault();
    };
    
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    
    render() {
        const { email, password, error } = this.state;
        const { t } = this.props;
        
        return (
            <form onSubmit={this.onSubmit} autoComplete="new-password" className="signin">
                <header>{t('signin.title')}</header>
                <div className="form">
                    <h4>
                        <Trans i18nKey='signin.description'>
                            send <span>50kr</span> / photo
                        </Trans>
                    </h4>
    
                    <input
                        name="email"
                        className="akt-form-control form-control"
                        value={email}
                        onChange={this.onChange}
                        type="text"
                        placeholder={t('signin.login')}
                        disabled={this.state.disabled}
                    />
                    <input
                        name="password"
                        className="akt-form-control form-control"
                        value={password}
                        onChange={this.onChange}
                        type="password"
                        placeholder={t('signin.password')}
                        disabled={this.state.disabled}
                    />
                    <label>
                        <input
                          type="checkbox"
                          disabled={this.state.disabled}
                        />
                        <span style={{marginLeft: 0.5 + 'rem'}}>
                            {t('signin.recommendations')}
                        </span>
                    </label>
                    <button
                      type="submit"
                      className="btn btn-primary btn-wide"
                      disabled={this.state.disabled}
                    >
                        {t('signin.submit')}
                    </button>

                    {error && <p className="invalid">{t(error)}</p>}

    
                    <PasswordForgetLink t={t} />
    
                </div>
            </form>
        );
    }
}

let SignInForm = compose(
    withRouter,
    withFirebase,
    withTranslation()
)(SignInFormBase);

SignInForm = connect()(SignInForm);

export { SignInForm };