import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { swishCheckout } from "../../service/http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import AnimatedButton from "../AnimatedButton";


class PurchaseSwishForm extends Component {
  
  state = {
    payer: '',
    message: '',
    validation: {},
    disabled: false
  };
  
  constructor(props) {
    super(props);
    
    this.onChange = this.onChange.bind(this);
  }
  
  componentDidMount = async () => {
  
  };
  
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value, validation: {}, result: {} });
  }
  
  submit() {
    const user = this.props.user;
    if (!this.state.payer) {
      this.setState({validation: { payer: this.props.t('purchase.swish.validation.payer_wrong')} });
      return;
    }
    if (!this.props.competition) {
      return;
    }
    
    this.setState({ disabled: true });
    
    swishCheckout(this.props.competition.id, user, this.state.payer, this.state.message, this.props.count)
      .then(response => response.json())
      .then(response => {
        let error = null;
        if (response.error) {
          try {
            error = JSON.parse(response.error)[0];
          } catch (e) {
            error = { errorCode: '' }
          }
        }
        console.log(error);
        
        if (!error) {
          this.setState({ complete: response.id, payer: '', message: 'no error supplied', disabled: false });
          return;
        }
        
        switch (error.errorCode) {
          case 'RP06' :
            this.setState({ validation: { payer: this.props.t('purchase.swish.validation.request_exists') } });
             break;
          case 'ACMT03':
          case 'PA01':
            this.setState({ validation: { payer: this.props.t('purchase.swish.validation.payer_wrong') } });
            break;
          case 'RP02': {
            this.setState({ validation: { message: this.props.t('purchase.swish.validation.message_wrong') } });
            break;
          }
          default:
            this.setState({ validation: { common: this.props.t('purchase.swish.validation.common') } })
        }
        
        this.setState({ disabled: false })
      })
      .catch(() => {
        this.setState({ validation: { common: this.props.t('purchase.swish.validation.common') }, disabled: false })
      });
  
  }
  
  
  render() {
    const { t } = this.props;
    if (this.state.complete) {
      return (
        <div id="purchase_swish" className="payment-method-body">
          <FontAwesomeIcon className="purchase_swish__exclamation" icon={faExclamationCircle} /><h6 className="purchase_swish__complete">{t('purchase.swish.message_complete')}</h6>
        </div>
      )
    }
    return (
      <div id="purchase_swish" className="payment-method-body">
    
        <Form className="payment-method-body-form row" onSubmit={event => {event.preventDefault();}}>
          <Form.Group className="col-12">
            <Form.Control
              id="amount"
              name="amount"
              className="payment-method-body-form-payer"
              type="text"
              value={this.props.total}
              autoComplete="off"
              disabled={true}
              placeholder={t('purchase.swish.amount_placeholder')}
            />
          </Form.Group>
          <Form.Group className="col-12">
        
            <Form.Control
              id="payer"
              name="payer"
              className={"payment-method-body-form-amount " + (this.state.validation.payer ? 'is-invalid' : '')}
              type="text"
              onChange={this.onChange}
              value={this.state.payer}
              autoComplete="off"
              placeholder={t('purchase.swish.payer_placeholder')}
              disabled={this.state.disabled}
            />
            {
              this.state.validation.payer
                ?
                <Form.Control.Feedback type="invalid">
                  {this.state.validation.payer}
                </Form.Control.Feedback>
                : null
            }
          </Form.Group>
          <Form.Group className="col-12">
            <Form.Control
              id="message"
              name="message"
              className={"payment-method-body-form-message " + (this.state.validation.message ? 'is-invalid' : '')}
              autoComplete="off"
              onChange={this.onChange}
              value={this.state.message}
              placeholder={t('purchase.swish.message_placeholder')}
              as="textarea"
              rows="3"
              disabled={this.state.disabled}
            />
            {
              this.state.validation.message
                ?
                <Form.Control.Feedback type="invalid">
                  {this.state.validation.message}
                </Form.Control.Feedback>
                : null
            }
          </Form.Group>
      
          <Form.Group className="payment-method-body-form-group-agree col-12">
          <AnimatedButton
            clazz="payment-method-body-form-submit"
            disabled={this.state.disabled}
            inProgress={this.state.disabled}
            onClick={() => this.submit()}
            buttonText={t('common.submit')}
          />
            {/*<button
                          type="submit"
                          className="btn btn-primary payment-method-body-form-agree-checkbox"
                          onClick={() => this.submit()}
                          disabled={this.state.disabled}
                        >
              {t('common.submit')}
            </button>
            */}
          </Form.Group>
        </Form>
  
      </div>
    );
  }
}

export default PurchaseSwishForm;
