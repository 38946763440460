import React, { Component } from "react";
import './style.scss';
import { withTranslation } from "react-i18next";


class Portfolio extends Component {


  render () {
    const { t } = this.props;
    let clazz = '';

    switch(this.props.index) {
        case 0 : 
            clazz = 'first';
            break;        
        case 1 : 
            clazz = 'second';
            break;        
        case 2 : 
            clazz = 'third';
            break;

    }



    return (
        <div className={'item ' + clazz}>
            <div className="photo">
                <img src={require('../../../../assets/imgs/picture.jpg')} alt=""/>
                <div className="number">{(this.props.index === 0 ? '' : (this.props.index + 1))}</div>
            </div>
            
            <div className="name">{this.props.person.name}</div>
            <div className="age">{this.props.person.age} {t('landing.winners.years')}</div>
            <div className="category">{t('landing.winners.category')}: Adipiscing elit</div>
            <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos maiores mollitia nemo odio sed vero!</div>
        </div>

    );
   }
}

export default withTranslation()(Portfolio);
