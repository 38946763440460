import React from 'react';
import './style.scss';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//TODO complete translations
const Footer = ({t}) => (
    <div className="footer">
            <div className="footer-links">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <span>{t('footer.quick_link')}</span>
                            <div className="footer-links-body row">
                                <div className="col-6">
                                    <Link className="left" to={'/portfolios'}>
                                        <p>{t('footer.main_page')}</p>
                                    </Link>
                                    <Link className="left" to={'/competitions'}>
                                        <p>{t('footer.competitions')}</p>
                                    </Link>
                                    <Link className="left" to={'/buysell'}>
                                        <p>{t('footer.buysell')}</p>
                                    </Link>
                                </div>
                                <div className="col-6">
                                    <Link className="left" to={'/signup'}>
                                        <p>{t('footer.new_account')}</p>
                                    </Link>
                            

                                    <Link className="left" to={'/contact'}>
                                    <p>{t('footer.help')}</p>
                                </Link> 
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <span>{t('footer.company')}</span>
                            <div className="footer-links-body">
                                
                                <Link className="left" to={'/team'}>
                                    <p>{t('footer.team')}</p>
                                </Link>   
                                <Link className="left" to={'/rules'}>
                                    <p>{t('footer.guides')}</p>
                                </Link>                             
                                
                            </div>
                        </div>
                        <div className="col-md-2">
                            <span>{t('footer.resources')}</span>
                            <div className="footer-links-body">
                                <Link className="left" to={'/terms-and-conditions'}>
                                    <p>{t('footer.terms_and_cond')}</p>
                                </Link>
                                <Link className="left" to={'/cookies'}>
                                    <p>{t('footer.cookies')}</p>
                                </Link>
                                <Link className="left" to={'/privacy-policy'}>
                                    <p>{t('footer.privacy_policy')}</p>
                                </Link>
                                <Link className="left" to={'/qa'}>
                                    <p>{t('footer.qa')}</p>
                                </Link>                                  
                            </div>
    
                        </div>

                    </div>
                </div>
            </div>
            <div className="footer-divider"/>
            <div className="footer-contacts">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 footer-contacts-line">
                            <div className="d-flex flex-row">
                                <div className="footer-icon-container">
                                    <img src={require('../../assets/imgs/icon_location.png')} className="img-fluid footer-icon" alt={''}/>
                                </div>
                                <div className="d-flex flex-column">
                                    <span>Husarvikstorget 16</span>
                                    <span >115 47 Stockholm</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 footer-contacts-line">
                            <div className="d-flex flex-row">
                                <div className="footer-icon-container">
                                    <img src={require('../../assets/imgs/icon_phone.png')} className="img-fluid footer-icon" alt={''}/>
                                </div>
                                <span>+46 (0)70 398 66 99</span>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="footer-icon-container">
                                    <img src={require('../../assets/imgs/icon_mail.png')} className="img-fluid footer-icon" alt={''}/>
                                </div>
                                <a href="mailto:info@aktiespelet.se"><span>info@aktiespelet.se</span></a>
                            </div>
                        </div>
                        <div className="col-md-3 footer-contacts-line" style={{marginBottom: 32}}>
                            <span>{t('footer.get_connected')}</span>
                            <div className="d-flex flex-row">
                                <div className="footer-social-icon-container">
                                    <a href="https://www.facebook.com/aktiespelet" target="_blank"><img src={require('../../assets/imgs/social_facebook.png')} className="img-fluid footer-social-icon" alt={''}/></a>
                                </div>
                                <div className="footer-social-icon-container">
                                   <a href="https://twitter.com/aktiespelet_se" target="_blank"> <img src={require('../../assets/imgs/social_twitter.png')} className="img-fluid footer-social-icon" alt={''}/></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 footer-contacts-line">
                            <div className="footer-logo-icon-container">
                                <img src={require('../../assets/imgs/logo_icon.png')} alt='' className='img-fluid'/>
                            </div>
                            <span>Aktiespelet Sverige AB</span>
                        </div>
                    </div>
                </div>
            </div>
    </div>
);

export default withTranslation()(Footer);