import { combineReducers } from 'redux';

import { LOAD_TOGGLE } from './Actions';


const initialState = {
    isLoading: false
}

function stocksApp(state = initialState, action) {

    switch (action.type) {
        case LOAD_TOGGLE:
            return { isLoading: action.isLoading };
    }
    return state
}

export default stocksApp;