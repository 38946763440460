import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {

        render() {
            return (
                <AuthUserContext.Consumer>
                    {value =>
                        condition(value.authUser) ? <Component {...this.props} user={value.authUser} configuration={value.configuration} /> : null
                    }
                </AuthUserContext.Consumer>
            );
        }
    }
    
    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorization);
};

export default withAuthorization;