import React from "react";


const CompetitionDetails = ({competition, t}) => 
  (
                        <div>
                          <div className="row table table__selected table-divider">
                            <div className="col-md-12 ">
                              <hr/>
                            </div>
                          </div>
                          <div className="row table table__selected table-competition-content">
                            <div className="col-md-2 col-4 table-column-cell table-competition-content-cell-status">
                              <div className="table-column-cell-content">
                                <span className="badge badge-success">{t('common.active')}</span>
                                <span className="table-column-cell-content-label">{t('competitions.status')}</span>
                              </div>
                            </div>
                            <div className="col-md-3 col-4 table-column-cell table-competition-content-cell-courtage">
                              <div className="table-column-cell-content">
                                <h6>60-65 kr</h6>
                                <span className="table-column-cell-content-label">{t('competitions.courtage')}</span>
                              </div>
                            </div>
                            <div className="col-md-2 col-4 table-column-cell table-competition-content-cell-registeredportfolios">
                              <div className="table-column-cell-content">
                                <h6>{competition.portfoliosCount}</h6>
                                <span className="table-column-cell-content-label">{t('competitions.portfolios_count')}</span>
                              </div>
                            </div>
                            <div className="col-md-1 table-column-cell table-column-cell-puff">
        
                            </div>
                            <div className="col-md-2 col-6 table-column-cell table-competition-content-cell-startdate">
                              <div className="table-column-cell-content">
                                <h6>{competition.enrollmentStart}</h6>
                                <span className="table-column-cell-content-label">{t('competitions.registration_start_date')}</span>
                              </div>
                            </div>
                            <div className="col-md-2 col-6 table-column-cell table-competition-content-cell-enddate">
                              <div className="table-column-cell-content">
                                <h6>{competition.enrollmentEnd}</h6>
                                <span className="table-column-cell-content-label">{t('competitions.registration_end_date')}</span>
                              </div>
                            </div>
                            <div className="col-md-12 table-column-cell table-column-cell-divider">
                              <hr/>
                            </div>
                            <div className="col-md-6 table-column-cell table-competition-content-cell-moreinfo">
                              <h6>{t('competitions.more_competition_info')}</h6>
                              <p>
                                {competition.content.description}
                              </p>
                            </div>
                            <div className="col-md-6 table-column-cell table-competition-content-cell-prize">
                              <h6>{t('competitions.price')}</h6>

                              <div className="row table table-competition-prize">
                                {
                                  competition.content.prizes.map((p, index) => (
                                    <div key={index} className="col-md-6 col-6 table-column-cell table-competition-prize-cell">
                                      <span className="table-competition-prize-cell-place">{index + 1}</span>
                                      <span className="table-competition-prize-cell-prize">{ p }</span>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>  	
  )

export default CompetitionDetails;
