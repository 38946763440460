export const LANDING = '/';
export const MESSAGE = '/message/:type/:code';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const PORTFOLIOS = '/portfolios';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const BUY_SELL = '/buysell';
export const PURCHASE = '/purchase/:id';
export const COMPETITIONS = '/competitions';
export const CONTACT = '/contact';
export const TEAM = '/team';
export const COOKIES = '/cookies';
export const PRIVACY_POLICY = '/privacy-policy';
export const QA = '/qa';

export const USERS = '/users';
export const FILTER = '/filter';
export const DUELS = '/duels';


export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const RULES = '/rules';

