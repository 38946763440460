import React, { Component } from "react";
import './style.scss';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, withTranslation } from "react-i18next";
import Portfolio from "./Portfolio";
import { withFirebase } from '../../Firebase';


class LeadingPortfolios extends Component {

    state = {
        portfolios: []
    };

    componentDidMount() {
        //const now = new Date('2019-01-24');
        const now = new Date();
        const isAdmin = this.props.user ? this.props.user.isAdmin() : false;
        this.props.firebase.getLeadindPortfolios(10, now, isAdmin).then(portfolios => {
            portfolios = portfolios.filter(p => p.place);
            this.setState({ portfolios: portfolios });
        });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="leaders">
       
            <div className="container">
                <header>
                    <h2><Trans i18nKey="landing.ongoing.leaders1">Leaders and there<br/> portfolio value</Trans></h2>
                    <p dangerouslySetInnerHTML={{ __html: t('landing.ongoing.leaders2') }}></p>
                </header>
                <div className="items">
                    <ul className="row">
                      {this.state.portfolios.map((value, index) => {
                        return (                        
                          <li key={index} className="col-md-6">
                            <Portfolio  portfolio={value} index={index} />
                          </li>);
                      })}

                     </ul>
                </div>
               </div>
        </div>

        );
    }
}

export default withTranslation()(withFirebase(LeadingPortfolios));