import React, { Component } from "react";
import './style.scss';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { withFirebase } from '../../Firebase';
import Winner  from "./Winner";


class Winners extends Component {

   state = {

    winners: [
      {
        name : 'Hacker',
        age : 21

      },      
      {
        name : 'Pro',
        age : 21

      },      
      {
        name : 'Noob',
        age : 21

      }
    ]
  };

   componentDidMount() {

    };
  
  render () {
    const { t } = this.props;
    return (
        <div className="winners">
            <div className="container">
                <header>
                    <h2>{t('landing.winners.title')}</h2>
                    <p>{t('landing.winners.description')}</p>
                </header>
                <div className="items">
                    <ul className="row">
                      {this.state.winners.map((value, index) => {
                        return (                        
                        <li className="col-md-4">
                          <Winner index={index} person={value}></Winner>
                        </li>);
                      })}
                    </ul>
                    <a href="#" className="btn btn-primary">{t('landing.winners.view_all')}</a>
                </div>
            </div>
        </div>

    );
   }
}

export default withTranslation()(withFirebase(Winners));
