import React, { Component } from "react";
import './style.scss';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, withTranslation } from "react-i18next";
import { roundAsString } from "../../../../utils/math";



class Portfolio extends Component {


  render () {
    const { t } = this.props;
    return (                            
        <div className= {(this.props.index === 0 ? 'item first' : 'item')}>
            <div className="number">{(this.props.index === 0 ? '' : (this.props.index + 1))}</div>
            <div className="text">
                <h4>{this.props.portfolio.owner.userName}</h4>
                <p>{t('landing.ongoing.portfolio_value')}</p>
            </div>
            <div className="value">{roundAsString(this.props.portfolio.value)}</div>
    </div>

    );
   }
}

export default withTranslation()(Portfolio);
