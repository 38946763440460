import React, { Component } from "react";
import './style.scss';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Duel from "./Duel";
import { Trans, withTranslation } from "react-i18next";
import { withFirebase } from '../../Firebase';


class Duels extends Component {

    state = {
        duels: []
    };

    componentDidMount() {
        //const now = new Date('2019-01-24');
        const now = new Date();
        this.props.firebase.findDuels(now).then(duels => {
            this.setState({ duels: duels });
        });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="duels">
       
            <div className="container">
                <header>
                    <h2><Trans i18nKey="landing.duels.title">Senaste dueller</Trans></h2>
                    <p dangerouslySetInnerHTML={{ __html: this.props.content ? this.props.content.subtitle : '' }}></p>
                </header>
                <div className="items">
                    <ul className="row">
                      {this.state.duels.map((value, index) => {
                        return (                        
                          <li key={index} className="col-md-12">
                            <Duel value={value}/>
                          </li>);
                      })}

                     </ul>
                </div>
                <div className="container-footer">
                    <a href="/duels">Läs mer</a>
                </div>

               </div>
        </div>

        );
    }
}

export default withTranslation()(withFirebase(Duels));