import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import { toggleLoad } from '../../redux/Actions';
import { connect } from 'react-redux';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            
            this.state = {
                authUser: null,
            };
        }
        
        componentDidMount() {
            this.props.firebase.auth.onIdTokenChanged((token) => {
              if (!token && this.state.authUser) {
                this.setState({authUser: null, configuration: null})
              }
            });
            this.listener = this.props.firebase.auth.onAuthStateChanged(

                async (authUser) => {
                    setTimeout(() => this.props.dispatch(toggleLoad(false)), 500);
                    let user = null;
                    let configuration = null;
                    if (authUser) {
                        user = await this.props.firebase.getUser(authUser.uid);
                        configuration = await this.props.firebase.getConfiguration();
                        const token = await authUser.getIdToken(true);
                        localStorage.setItem('token', token);
                    }
                    this.setState({ authUser: user, configuration });
                },
            );
        }
        
        componentWillUnmount() {
            this.listener();
        }
        
        render() {
            return (
                <AuthUserContext.Provider
                  value={{
                      authUser: this.state.authUser,
                      configuration: this.state.configuration,
                  }}
                >
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }
    
    return connect()(withFirebase(WithAuthentication));
};


export default withAuthentication;