import React  from 'react';
import { withTranslation } from "react-i18next";

import './styles.scss';

const Team  = ({t}) =>
    (<div className="container"><div className="team">
    	<h5>{t('team.title')}</h5>
			<p>Aktiespelet Sverige AB är grundat av Joakim Han, som även är VD på företaget. Vår vision är att vi ska skapa de mest verklighetstrogna aktietävlingarna med de attraktivaste vinsterna. </p>
			<p>Vår första tävling som startar i slutet av sept 2019 kommer vi ha minst 30.000 SEK som förstapris. Vårt långsiktiga mål är att 1:a priset ska vara minst 500.000 SEK/ tävlingsomgång. Varje tävlingomgång är 3-4 veckor lång.</p>
<p>Aktiespelet Sverige AB<br />
Husarvikstorget 16<br />
115 47 Stockholm<br />
E-post: <a href="mailto:info@aktiespelet.se">info@aktiespelet.se</a><br />
Tel: +46 (0)70 398 66 99
</p>
    </div>
    </div>
);


export default withTranslation()(Team);