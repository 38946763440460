import React  from "react";
import { withAuthorization } from "../Session";
import { Badge } from "react-bootstrap";

import './style.scss';
import moment from "moment-timezone";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { isCompetitionAvailableToBuy } from "../../utils/common";
import { claimFreePortfolio, useBenefitPortfolio } from "../../service/http";
import AbstractComponent from "../AbstractComponent";
import * as ROUTES from '../../constants/routes';

import CompetitionForm from "./CompetitionForm";
import Competition from "./Competition";

const handlePrizes = (prize) => Object.keys(prize).sort((a, b) => (+a - +b)).map(key => prize[key]);

const handleRawCompetitions = (competitions) => {
    if (!competitions) return [];

    const dateFormat = 'YYYY.MM.DD';
    return competitions.map(item => ({
        id: item.id,
        buyable: isCompetitionAvailableToBuy(item, new Date()),
        portfoliosCount: item.portfoliosCount || 0,
        courtage: item.courtage || 0,
        name: item.name,
        description: item.description,
        start: moment(item.startDate.toDate()).format(dateFormat),
        end: moment(item.endDate.toDate()).format(dateFormat),
        enrollmentStart: moment(item.enrollmentStart.toDate()).format(dateFormat),
        enrollmentEnd: moment(item.enrollmentEnd.toDate()).format(dateFormat),
        price: item.price || {},
        prize: item.prize ? handlePrizes(item.prize) : [],
        content: item.content,
        freePortfoliosPerCompetitor: item.freePortfoliosPerCompetitor
    }));

};

const enhanceCompetitionsByTotalNumberOfPortfolios = async (firebase, competitions) => {

    if (!competitions.length) return Promise.resolve();
    return Promise.all(competitions.map(competition => {
      return firebase.readNumberPortfoliosForCompetition(competition).then(count => competition.portfoliosCount = count);
    }));
}

const enhanceCompetitionsByNumberOfPortfoliosForUser = async (firebase, competitions, user) => {


    if (!user) {
        return Promise.all(competitions.map(competition => 0));
    }

    return Promise.all(competitions.map(async competition => (await firebase.findPortfoliosForCompetitionsForUser(user, competition)).length));
}

const findNumberOfBenefitPortfolios = async (firebase, user) => {
    if (!user) {
        return 0;
    }
    return (await firebase.findBenefitPortfolios(user)).length;
}

class CompetitionsPage extends AbstractComponent {

    state = {
        competitions: [],
        competitionToCount: {}
    };

    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onFreePortfolioClicked = this.onFreePortfolioClicked.bind(this);
        this.onBenefitPortfolioClicked = this.onBenefitPortfolioClicked.bind(this);
    }

    componentDidMount = async () => {

        this.scrollUp();
        this.fetchCompetitions(this.props.user).then( availableCompetitions => {

            if (availableCompetitions.length > 0) {
            this.state.competitions = availableCompetitions;
                this.state.selected = null;
                this.onSelect(availableCompetitions[0].id);
                this.onOpen(availableCompetitions[0].id, false);
            }
            this.fetchCompetitions(this.props.user);  // ugly patch
        });
    };

    onFreePortfolioClicked(competition) {
        return claimFreePortfolio(competition).then(() => {
            this.props.history.push(ROUTES.PORTFOLIOS);
        });

    }

    onBenefitPortfolioClicked(competition) {
        return useBenefitPortfolio(competition).then(() => {
            this.props.history.push(ROUTES.PORTFOLIOS);
        });

    }

    componentDidUpdate = async prevProps => {
        // Typical usage (don't forget to compare props):
        if (!prevProps.user && this.props.user) {
          this.fetchCompetitions(this.props.user);
        }
    }

    fetchCompetitions = async user => {

        const isAdmin = user ? user.isAdmin() : false;
        const now = new Date();
        const availableCompetitions = await this.props.firebase.findVisibleOngoingCompetitions(now, isAdmin);

        const enhancedCompetitions = handleRawCompetitions(availableCompetitions);


        if (availableCompetitions.length > 0) {
            this.state.selected = null;
            this.onSelect(availableCompetitions[0].id);

            this.onOpen(availableCompetitions[0].id, false);
        }

        await enhanceCompetitionsByTotalNumberOfPortfolios(this.props.firebase, this.state.competitions);

        enhanceCompetitionsByNumberOfPortfoliosForUser(this.props.firebase, this.state.competitions, this.props.user).then(values => this.updateEnhancedByNumberOfPortfoliosForUserCompetitions(this.state.competitions, values));
        findNumberOfBenefitPortfolios(this.props.firebase, this.props.user).then(benefitPortfolios => this.setState({ benefitPortfolios }));

        return enhancedCompetitions;
    }

    updateEnhancedByNumberOfPortfoliosForUserCompetitions(competitions, enhancedCompetitions) {
        competitions.forEach((c, i) => c.freePortfoliosForUser = c.freePortfoliosPerCompetitor - enhancedCompetitions[i]);
        this.setState({ competitions: competitions });
    }

    onSelect(selected) {
        if (this.state.selected === selected) return;
        const competitions = this.state.competitions.map(c => { return c.id === selected ? { ...c, selected: true, opened: false } : { ...c, selected: false, opened: false } });
        this.setState({ competitions, selected });
    }

    onOpen(id, opened) {
        if (this.state.selected !== id) return;
        const competitions = this.state.competitions.map(c => { return c.id === id ? { ...c, opened: !opened } : { ...c, opened: false } });
        this.setState({ competitions });
    }


    render() {
        return (
            <div ref={this.ref}>
                <CompetitionsPageScreen
                  t={this.props.t}
                  competitions={this.state.competitions}
                  onSelect={this.onSelect}
                  onOpen={this.onOpen}
                  benefitPortfolios={this.state.benefitPortfolios}
                  onFreePortfolioClicked={this.onFreePortfolioClicked}
                  onBenefitPortfolioClicked={this.onBenefitPortfolioClicked}
                  user={this.props.user}/>
              </div>
        );
    }
}

const CompetitionsPageScreen = ({ competitions = [], onSelect = () => {}, benefitPortfolios = 0, onOpen = () => {}, onFreePortfolioClicked = () => {}, onBenefitPortfolioClicked = () => {}, t, user }) =>
    (
        <div className="content competitions">
            <Helmet>
                <title>{t('meta.title.competitions')}</title>
            </Helmet>
      <div className="header">
        <div className="container">
          <h5>{t('competitions.title')}</h5>
          <h6>{t('competitions.sub_title')}</h6>
        </div>
      </div>

      {
        benefitPortfolios > 0 &&
        <div className="container">
          <Badge variant="success">{ t('portfolios.benefit_portfolios', {portfolios : benefitPortfolios}) }</Badge>
        </div>

      }

      
      <div className="container competitions-table">
   
                  <div className="row table table-header table__dark">
                    <div className="col-md-2 table-column-header">{t('competitions.name')}</div>
                    <div className="col-md-2 table-column-header">{t('common.start_date')}</div>
                    <div className="col-md-2 table-column-header">{t('common.end_date')}</div>
                    <div className="col-md-2 table-column-header">{t('competitions.portfolio_value')}</div>
                    <div className="col-md-2 table-column-header">{t('competitions.cost_to_buy')}</div>
                    <div className="col-md-1 table-column-header">{t('common.buy')}</div>
                    <div className="col-md-1 table-column-header">{t('competitions.expand')}</div>

                  </div>
        
                  {
                    competitions.map(competition =>
                      (
                        <Competition
                          key={competition.id}
                          competition={competition}
                          t={t} onSelect={onSelect}
                          onOpen={onOpen}
                          isLoggedIn={!!user}
                          benefitPortfolios={benefitPortfolios}
                          onFreePortfolioClicked={onFreePortfolioClicked}
                          onBenefitPortfolioClicked={onBenefitPortfolioClicked}
                          isPurchasable={user && competition.buyable}>
                        </Competition>
                      )
                    )
                  }

                  {

                    (user && user.isAdmin()) &&
                    <div className="row ">
                      <div className="col-md">
                        <CompetitionForm />
                      </div>
                    </div>
                  }
      </div>
    </div>
    );


const condition = authUser => true;

export default withAuthorization(condition)(withTranslation()(CompetitionsPage));
