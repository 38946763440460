import React from 'react';

import { withFirebase } from '../Firebase';
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const SignOutAsButton = ({ firebase }) => (
    <button type="button" onClick={firebase.doSignOut}>
        Sign Out
    </button>
);

const SignOutAsItem = ({ firebase }) => (
    <Link className='nav-link' to={ROUTES.LANDING} onClick={firebase.doSignOut}>Logout</Link>
);


const SignOutButton = withFirebase(SignOutAsButton);
const SignOutItem = withFirebase(SignOutAsItem);

export { SignOutButton, SignOutItem };