import React  from 'react';


import './styles.scss';

const TermsAndConditions  = () =>
    (<div className="container"><div className="terms-and-conditions">
        <h5> Köpvillkor</h5>
        <ul>
            <li>
            <span className="title"> 1.Företagsinformation</span>
            <p>
            www.aktiespelet.se tillhandahålls av Aktiespelet Sverige AB med Org nummer 559173-5328. Information &amp; bilder på hemsidan ägs av företaget eller partner och får inte kopieras utan tillåtelse
            </p>
            </li>            
            <li>
            <span className="title">2. Priser</span>
            <p>
            Varje vara anges inklusive moms i Svenska kronor. I kundvagnen ser man det totala priset inklusive
alla avgifter så som moms. Varje portfölj du köper i Aktiespelet kostar minst 50 kronor.
Du kan välja att betala via Swish eller kontokort. Anmälan är bindande.
</p>
            </li>
            <li>            
            <span className="title">3 Betalning</span>
            <p>
Betalning sker genom vald betalningsmetod i kassan. Betalningsmetoden tillhandahålls av Bambora
tillsammans med samarbetspartners.
</p>
            </li>            
            <li>            
            <span className="title">3.1 Kortbetalning</span>
            <p>
Korbetalning accepteras av Visa, MasterCard och Maestro. Debitering av ditt betalkort sker direkt.
</p>
            </li>
            <li>            
            <span className="title">3.2 Swish betalning</span>
            <p>
Betalning sker genom vald betalningsmetod i kassan. Betalningsmetoden tillhandahålls av Swish.
</p>
            </li>                 
            <li>            
            <span className="title">3.3 Ångerrätt</span>
            <p>
Det finns ingen ångerrätt på köpta tävlingar i Aktiespelet Sverige AB:s aktietävlingar.
</p>
            </li>            
            <li>            
            <span className="title">4. Personuppgiftsbehandling</span>
            <p>
För att kunna ta emot, hantera och leverera din beställning kommer Aktiespelet Sverige AB att
behandla dina personuppgifter. All vår behandling av personuppgifter sker i enlighet med gällande
personuppgiftslagstiftning. Du kan läsa mer om hur vi behandlar dina personuppgifter under Personuppgiftsbehandling.
</p>
            </li>            
            <li>            
            <span className="title">5. Kontakt &amp; Kundtjänst</span>
            <p>
Kontaktuppgifter till oss finner ni <a href="/team">här</a>.
</p>
            </li>            
            <li>            
            <span className="title">6. Cookies</span>
            <p>
En cookie är en textfil som skickas från en websida till din dator där den lagras antingen i minnet
(session cookies) eller som en liten textfil (textbaserade cookies). Cookies används för att lagra t.ex.
inloggningsinformation eller din kundvagn när du surfar runt på olika webbplatser. Om din webbläsare
är inställd på att inte acceptera cookies kommer du inte ha möjlighet att göra någon beställning på vår
sida. Du kan lätt ändra detta under inställningar i din webbläsare. Observera att vi inte använder
cookies för att spara personlig information om dig
</p>
            </li>            
            <li>            
            <span className="title">7. Force Majeure</span>
            <p>
Force Majeure såsom krig, omfattande arbetskonflikt, blockad, eldsvåda, miljökatastrof, allvarlig
smittspridning eller annan omfattning som part inte råder över och som förhindrar part att fullgöra sina
skyldigheter och befriar part från detta. Sådan befrielse gäller under förutsättning att verksamheten
inte heller kan bedrivas under dessa förhållanden. Motparten skall omedelbart underrättas om
omständigheter som kan tillämpning denna bestämmelse
</p>
            </li>            
            <li>            
            <span className="title">7.1 Ändring av avtalsvillkor</span>
            <p>
Efter att kund genomfört ett köp äger Säljföretaget inte rätt att ändra villkoren för aktuellt köp om inget
annat är överenskommet
</p>
            </li>            
            <li>            
            <span className="title">7.2 Klagomål</span>
            <p>
Har du som kund klagomål kan dessa framföras via våra kontaktsätt som ni finner <a href="/contact">här</a>
</p>
            </li>            
            <li>            
            <span className="title">7.3 Tvister &amp; lagar</span>
            <p>
Svensk rätt skall tillämpas på samtliga köp enligt dessa avtalsvillkor. Tvist om köp enligt dessa
avtalsvillkor skall exklusivt prövas av svensk allmän domstol.
</p>
            </li>      
              <li>            
            <span className="title">7.4 Tävlingar</span>
            <p>
Om en tävling inte når upp till enligt Aktiespelet Sverige AB:s godtagabara deltagarantal, så har Aktiespelet Sverige AB rätt att ställa in en tävling innan tävlingen har startat. Om så görs skall Aktiespelet Sverige AB återbetala erlagd deltagaravgift till alla tävlande.
</p>
            </li>             
        </ul>
    </div>
    </div>
);


export default TermsAndConditions;