import React, { Component } from 'react';
import './style.scss';
import Loader from 'react-loader-spinner'
import Button from 'react-bootstrap/Button'


class AnimatedButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Button
                className={"btn btn-primary btn-animated " + this.props.clazz}
                type="submit"
                onClick={this.props.onClick}
                disabled={this.props.disabled}>
                {
                    this.props.inProgress &&
                        <Loader
                            className="spinner"
                            type="TailSpin"
                            color="#fff"
                            height={30}
                            width={30}
                        />
                }
                <span className="btn-animated-text">{this.props.buttonText}</span>
            </Button>
        );
    }
}

export default AnimatedButton;
