import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import './animations.scss';
import App from './components/App';
import { createStore } from 'redux'
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/Firebase';

import { Provider } from 'react-redux';
import rootReducer from './redux/Reducers';
import { isDev } from './utils/common';

import './i18n';
import ReactGA from 'react-ga';


if (!isDev()) {
    ReactGA.initialize('UA-149582009-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const store = createStore(rootReducer)

ReactDOM.render(
    <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
        <App store={store}/>
    </FirebaseContext.Provider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();