import React, { Component } from "react";
import Form from "react-bootstrap/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenAlt,
    faTimes
} from "@fortawesome/free-solid-svg-icons";

import './style.scss';

class EditableHeader extends Component {

    state = {
        value: '',
        originalValue: '',
        isEditing: false

    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();


    }


    async componentDidMount() {
        this.setState({ value: this.props.value });
        this.props.onSubmit.bind(this);

        this.onEditPressed = this.onEditPressed.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

    };

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.inputRef.current.blur();
        }
    }

    onSubmit(event) {
        this.setState({ isEditing: false, originalValue: '' });

        if (this.state.originalValue === event.target.value) {
            return;
        }

        this.props.onSubmit(event.target.value);
    };

    setValue(value) {
        this.setState({ value: value });

    }

    onEditPressed(event) {
        this.setState(state => ({ isEditing: true, originalValue: state.value }), () => {
            this.inputRef.current.focus();
        });
    };
    onValueChange(event) {
        if (event.target.value || event.target.value === '') {
            this.setState({ value: event.target.value });
        }

    };


    render() {
        return (
            <div className="editable-header">

              {this.state.isEditing && 
                  <input
                    type="text"
                    value={this.state.value}
                    onChange={this.onValueChange}
                    onBlur={this.onSubmit}
                    ref={this.inputRef}
                    onKeyPress={this.handleKeyPress}
                  />
              }
              {!this.state.isEditing && <h6>{this.state.value} <FontAwesomeIcon className="editable-header-icon" icon={faPenAlt} onClick={this.onEditPressed}/></h6>}

              

            </div>
        )
    }
}

export default EditableHeader;