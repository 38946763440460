import moment from "moment-timezone";

const year = moment().tz('Europe/Stockholm').year();

const holidays = [
    // Januari - 1
    {
        date: new Date(year, 0, 1)
    },

    // April - 18 (half), 19, 22, 30 (half),
    {
        date: new Date(year, 3, 18),
        atHour: 13,
    },
    {
        date: new Date(year, 3, 19),
    },
    {
        date: new Date(year, 3, 22),

    },
    {
        date: new Date(year, 3, 30),
        atHour: 13
    },


    // Mai - 1, 29 (half), 30
    {
        date: new Date(year, 4, 1)
    },
    {
        date: new Date(year, 4, 29),
        atHour: 13
    },
    {
        date: new Date(year, 4, 30)
    },
    // Juni - 6, 21
    {
        date: new Date(year, 5, 6)
    },
    {
        date: new Date(year, 5, 21),
        atHour: 13
    },

    // November - 1 (half)
    {
        date: new Date(year, 10, 1),
        atHour: 13
    },
    // November - 1 (half)
    {
        date: new Date(year, 11, 24)
    },
    {
        date: new Date(year, 11, 25)
    },
    {
        date: new Date(year, 11, 26)
    },
    {
        date: new Date(year, 11, 31)
    }
];



export const isExchangeClosed = (date = new Date()) => {

    const now = moment(date).tz('Europe/Stockholm');
    const day = now.day();
    const hour = now.hour();
    const isWorkDay = day > 0 && day <= 5;
    const isWorkHours = hour >= 9 && hour <= 17;

    const holiday = holidays.find(d => now.isSame(d.date, 'day'));
    const isClosedOnHoliday = holiday && (holiday.atHour ? hour >= holiday.atHour : true);
    const isNotWorkingHours = !(isWorkDay && isWorkHours);

    return !!(isClosedOnHoliday || isNotWorkingHours);
};

export const getToken = () => {
    return localStorage.getItem('token');
}

export const getAuthHeader = () => {
    return { Authorization: `Bearer ${getToken()}` };
}

export const isCompetitionAvailable = (competition, date) => {
    const momentDate = moment(date);

    const enrollmentStart = moment(competition.enrollmentStart.toDate());

    return momentDate.isAfter(enrollmentStart);
}

export const isCompetitionAvailableToBuy = (competition, date) => {
    const momentDate = moment(date);

    const enrollmentStart = moment(competition.enrollmentStart.toDate());
    const enrollmentEnd = moment(competition.enrollmentEnd.toDate());

    return  momentDate.isAfter(enrollmentStart) && momentDate.isBefore(enrollmentEnd);
}

export const filterAvailableCompetitions = (date, competitions) => {
    return competitions.filter(competition => {
        return isCompetitionAvailableToBuy(competition, date);

    });
}
export const filterVisibleCompetitions = (date, competitions) => {
    return competitions.filter(competition => {
        return isCompetitionAvailable(competition, date);

    });
}
export const filterAvailablePortfolios = (date, portfolios) => {
    return portfolios.filter(portfolio => {
        const competition = portfolio.competition;
        return isCompetitionAvailable(competition, date);

    });
}

export const isDev = () => {
    return process.env.NODE_ENV === 'development';
}

export const debounce = (callback, time) => {
    let interval;
    return (...args) => {
        clearTimeout(interval);
        interval = setTimeout(() => {
            interval = null;
            callback(...args);
        }, time);
    };
};