import BuySellBlock from "./BuySell";
import { saleStock } from "../../service/http";
import { withTranslation } from "react-i18next";

class Sale extends BuySellBlock {

    getPrice() {
    return this.props.stock.bidprice;
  }
  
  validateShares(shares) {
    if (!super.validateShares(shares)) return false;
    
    return true;
  }
  
  validateAmount(amount) {
    if (!super.validateAmount(amount)) return false;
    
    return true;
  }
  
  submit() {
    if (!super.submit()) return;

    const numberOfSharesToSell = +this.state.shares;
    
    if (numberOfSharesToSell > this.props.stock.count) {
      this.setState({
        validation: {shares: this.props.t('sell.validation.shares_more_than')}
      });
      return;
    }
  
    this.setState({ disabled: true });
  
    saleStock(this.props.marketplace, this.state.portfolio, this.props.stock, numberOfSharesToSell, this.state.amount)
      .then(() => {
        this.props.paymentCompleted(this.props.stock, -numberOfSharesToSell, this.state.amount).then((portfolios) => {

          if (!(this.props.stock.count - numberOfSharesToSell)) {
            return;
          }
          
          let portfolio = this.state.portfolio;
          if (portfolios) {
            portfolio = portfolios.find(item => item.id === this.state.portfolio.id);
          }
          this.setState({
            shares: '',
            amount: '',
            portfolio,
            result: { success: this.props.t('common.payment.success') },
            disabled: false
          })
        });
      })
      .catch(data => {
        const message = this.props.t(`common.${data.error.unit}.${data.error.code}`);
        
        this.setState({
          shares: '',
          amount: '',
          result: { failed: message },
          disabled: false
        })
      });
  }
  
  
}

Sale.propTypes = {};

export default withTranslation()(Sale);