import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { withTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

const PasswordForgetPage = ({t}) => (
    <div className="container">
        <h1>{t('password_forget.title')}</h1>
        <PasswordForgetForm t={t} />
    </div>
);

const INITIAL_STATE = {
    email: '',
    error: null,
};

class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);
        
        this.state = { ...INITIAL_STATE };
    }
    
    onSubmit = event => {
        const { email } = this.state;
        
        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });
        
        event.preventDefault();
    };
    
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    
    render() {
        const { email, error } = this.state;
        const { t } = this.props;
        
        const isInvalid = email === '';
        
        return (
            <Form className="form" style={{padding: 1 + 'rem'}} onSubmit={this.onSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Control
                    name="email"
                    value={this.state.email}
                    className="akt-form-control form-control"
                    onChange={this.onChange}
                    type="text"
                    placeholder={t('password_forget.email_placeholder')}
                  />
  
                  <Button
                    className="btn btn-primary"
                    disabled={isInvalid}
                    type="submit">
                    {t('password_forget.reset')}
                  </Button>
                </Form.Group>
  
              </Form.Row>

              <Form.Row>
                {error && <div className="error-feedback">{error.message}</div>}
              </Form.Row>
            </Form>
        );
    }
}

const PasswordForgetLink = ({t}) => (
    <div className="help">
      {t('signin.forgot_password')} <Link to={ROUTES.PASSWORD_FORGET}>{t('signin.click_here')}</Link>
    </div>
);

export default withTranslation()(PasswordForgetPage);

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };