import React, { Component } from "react";
import './style.scss';
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, withTranslation } from "react-i18next";
import { roundAsString } from "../../../../utils/math";
import { withFirebase } from '../../../Firebase';


const goodPhrase = 'The real man';
const badPhrase = 'The looser';


class Duel extends Component {

    state = {};

    componentDidMount() {

        this.setState({ value: this.props.value })
        const challengerPortfolioPromise = this.props.firebase.getPortfolio(this.props.value.challengerPortfolio.id);
        const challengeePortfolioPromise = this.props.firebase.getPortfolio(this.props.value.challengeePortfolio.id);

        Promise.all([challengerPortfolioPromise, challengeePortfolioPromise]).then(result => {
            const challengerPortfolio = result[0];
            const challengeePortfolio = result[1];

            const value = this.state.value;


            value.challengerPortfolio = challengerPortfolio;
            value.challengeePortfolio = challengeePortfolio;

            this.setState({ value })

        })
    };



    render() {
        if (!this.state.value) {
            return null;
        }
        const { t } = this.props;
        const isChallengerWins = this.state.value ? this.state.value.challengeePortfolio.value <= this.state.value.challengerPortfolio.value : false;

        const challengeePhrase = isChallengerWins ? badPhrase : goodPhrase;
        const challengerPhrase = isChallengerWins ? goodPhrase : badPhrase;


        return (
            <div className='duel'>                         
          <div className='item challenger'>
           <div className="number">{this.state.value.challengerPortfolio.place }</div>
            <div className="text">
                <h4>{this.state.value.challenger.userName}</h4>
                <p>{challengerPhrase}</p>
            </div>
            <div className="value">{roundAsString(this.state.value.challengerPortfolio.value)}</div>
          </div>  
          <span className="versus">vs</span>        

          <div className='item challengee'>
           <div className="number">{this.state.value.challengeePortfolio.place }</div>
            <div className="text">
                <h4>{this.state.value.challengee.userName}</h4>
                <p>{challengeePhrase}</p>
            </div>
            <div className="value">{roundAsString(this.state.value.challengeePortfolio.value)}</div>
          </div>
        </div>

        );
    }
}

export default withTranslation()(withFirebase(Duel));