import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';

import { AuthUserContext } from '../Session';


import Navigation from '../Navigation';
import SignUpPage from '../SignUp';
import PasswordForgetPage from '../PasswordForget';
import BuySellPage from '../BuySell';
import PortfoliosPage from '../Portfolios';
import PurchasePage from '../Purchase';
import CompetitionsPage from '../Competitions';
import SupportPage from '../Support';
import MessagePage from '../MessagePage';
import Users from '../Users';
import SharesFilter from '../SharesFilter';
import {TermsAndConditions, Rules, Team, QA, Cookies, PrivacyPolicy, Duels} from '../StaticPages';
import {Helmet} from "react-helmet";


import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import LandingPage from "../Landing";
import Footer from "../Footer";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { isDev } from '../../utils/common';

import './styles.scss';
import NoMatch from "../NoMatch";
import { hotjar } from 'react-hotjar';

import  PersonalNumberAlert  from '../Portfolios/PersonalNumberAlert';
import  ActivationAlert  from '../Portfolios/ActivationAlert';

if (!isDev()) {
  hotjar.initialize(1472850, 6);
}

library.add(fab);


// loading component for suspense fallback
const Loader = () => (
  <div className="App akt">
    <div>loading...</div>
  </div>
);

const App = ({store}) => {
  return (
    <Router>
      <Helmet>
        
        <meta name="viewport" content="width=device-width, initial-scale=1.0,user-scalable=no"></meta>
        <link rel="apple-touch-icon" href="images/touch-icon-iphone-150.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="images/touch-icon-ipad-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="images/touch-icon-iphone-retina-180.png"/>
        <link rel="apple-touch-icon" sizes="167x167" href="images/touch-icon-ipad-retina_167.png"/>
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="a06c93f0-905f-4467-8ef2-39974849ddb9" type="text/javascript" async></script>
       </Helmet>
      
        <Suspense className="body" fallback={<Loader/>}>
          
          <Navigation store={store}/>
          <AuthUserContext.Consumer>
            {value => value.authUser && !value.authUser.personal && <PersonalNumberAlert user={value.authUser}/>}
          </AuthUserContext.Consumer>

          <AuthUserContext.Consumer>
            {value => value.authUser && !value.authUser.activated && <ActivationAlert email={value.authUser.email}/>}
          </AuthUserContext.Consumer>


          
          
          <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
            <Route exact path={ROUTES.PORTFOLIOS} component={PortfoliosPage} />
            <Route exact path={ROUTES.MESSAGE} component={MessagePage} />
            {/*<Route exact path={ROUTES.ACCOUNT} component={AccountPage} />*/}
            <Route exact path={ROUTES.BUY_SELL} component={BuySellPage} />
            <Route exact path={ROUTES.PURCHASE} component={PurchasePage} />
            <Route exact path={ROUTES.COMPETITIONS} component={CompetitionsPage} />
            <Route exact path={ROUTES.CONTACT} component={SupportPage} />
            <Route exact path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
            <Route exact path={ROUTES.RULES} component={Rules} />
            <Route exact path={ROUTES.TEAM} component={Team} />
            <Route exact path={ROUTES.QA} component={QA} />
            <Route exact path={ROUTES.COOKIES} component={Cookies} />
            <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />

            <Route exact path={ROUTES.USERS} component={Users} />
            <Route exact path={ROUTES.FILTER} component={SharesFilter} />
            <Route exact path={ROUTES.DUELS} component={Duels} />
            <Route component={NoMatch} />
          </Switch>
          <Footer/>
        </Suspense>
       
    </Router>
)};

export default withAuthentication(App);
